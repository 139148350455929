<template>
    <div class="flex" :class="[align === 'right' ? 'justify-end' : align === 'center' ? 'justify-center' : 'justify-start']">
        <label v-if="label" class="block mb-1 text-sm font-medium text-gray-700" :for="id">{{ label }} <span v-show="optional" class="inline-flex items-center ml-1 px-2 py-0.5 rounded-md text-xs font-normal bg-gray-100 text-gray-600 select-none">Optional</span></label>
    </div>

    <div class="flex gap-2" :class="[align === 'right' ? 'justify-end' : align === 'center' ? 'justify-center' : 'justify-start']">
        <slot />
    </div>
</template>

<script>
    export default {
        props: {
            label: { type: String, required: false },
            optional: { type: Boolean, default: false },
            align: {
                type: String,
                default: 'left',
            },
        },
    };
</script>
