<template>
    <div class="relative" v-click-outside="handleClickOutside">
        <label v-if="label" class="block mb-1 text-sm font-medium text-gray-700" :for="id">
            {{ label }}
            <span v-show="optional" class="inline-flex items-center ml-1 px-2 py-0.5 rounded-md text-xs font-normal bg-gray-100 text-gray-600 select-none">Optional</span>
        </label>
        <div>
            <input
                :id="id"
                type="text"
                @input="onChange"
                ref="input"
                v-model="searchInput"
                @keydown.down="onArrowDown"
                @keydown.up="onArrowUp"
                @keydown.enter="onEnter"
                @keydown.tab="handleClickOutside"
                :name="uuid()"
                autocomplete="new-password"
                autocorrect="off"
                class="w-full px-3 py-3 rounded border focus:outline-none focus:ring-2 focus:border-transparent"
                :class="[error ? 'border-red-500 text-red-900 placeholder-red-300 focus:ring-red-500' : 'border-gray-300 text-grey-900 placeholder-grey-300 focus:ring-indigo-600']"
            />
            <div v-show="error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
        </div>
        <div v-show="isOpen && searchInput.length >= minLengthForDropdown">
            <ul ref="scrollContainer" class="w-full absolute z-10 mt-2 w-full bg-white shadow-lg max-h-60 overflow-auto rounded border">
                <li v-if="results.length <= 0" class="text-gray-500 select-none relative py-2 pl-3 pr-9">
                    <span class="font-normal block truncate italic"> No options </span>
                </li>
                <li else v-for="(item, idx) in results" :key="item" :ref="(el) => optionRefs.push(el)" @click="onSelectItem(idx)" @mouseover.prevent="handleSelection(idx)" class="text-gray-900 cursor-pointer select-none relative px-3 py-2 pl-3 pr-9" :class="[idx == arrowCounter ? 'bg-gray-100' : 'bg-white']">
                    <span class="font-normal block truncate" v-html="makeBold(item[displayProperty], searchInput)" />
                </li>
            </ul>
        </div>
        <p v-show="error" class="mt-2 text-sm text-red-600">{{ error }}</p>
    </div>
</template>

<script>
    import { v4 as uuidv4 } from 'uuid';
    import { ExclamationCircleIcon } from '@heroicons/vue/solid';

    export default {
        components: {
            ExclamationCircleIcon,
        },
        props: {
            id: { type: String, required: true },
            label: { type: String, required: false },
            optional: { type: Boolean, default: false },
            modelValue: { required: true },
            options: { type: Array, required: true },
            scrollOffset: { type: Number, default: 3 },
            minLengthForDropdown: { type: Number, default: 0 },
            valueProperty: { type: String, required: true },
            displayProperty: { type: String, required: true },
            error: { type: String, required: false },
        },
        created() {
            const matchingOption = this.options.find((option) => option[this.valueProperty] === this.modelValue);
            console.log(this.options);
            console.log(this.modalValue);
            console.log(this.valueProperty);
            if (matchingOption) {
                this.searchInput = matchingOption[this.displayProperty];
            }
        },
        data() {
            return {
                searchInput: '',
                arrowCounter: 0,
                optionRefs: [],
                isOpen: false,
            };
        },
        computed: {
            results() {
                if (this.searchInput.length >= this.minLengthForDropdown) {
                    return this.options.filter((option) => option[this.displayProperty].replace(/\W/g, '').toLowerCase().includes(this.searchInput.replace(/\W/g, '').toLowerCase()));
                } else return [];
            },
        },

        methods: {
            onArrowDown(e) {
                e.preventDefault();
                this.handleSelectionDown();
            },
            onArrowUp(e) {
                e.preventDefault();
                this.handleSelectionUp();
            },
            onChange() {
                this.isOpen = true;
                this.arrowCounter = 0;
            },
            onEnter() {
                this.searchInput = this.results[this.arrowCounter][this.displayProperty];
                this.resetDropdown();
            },
            onSelectItem(idx) {
                this.searchInput = this.results[idx][this.displayProperty];
                this.resetDropdown();
            },
            resetDropdown() {
                this.isOpen = false;
                this.$refs.scrollContainer.scrollTop = 0;
                this.arrowCounter = 0;
                this.$nextTick(() => this.$refs.input.focus());
            },
            handleClickOutside() {
                this.isOpen = false;
                this.arrowCounter = -1;

                if (!this.checkValue(this.searchInput)) {
                    this.searchInput = '';
                }
            },
            handleSelectionDown() {
                if (this.arrowCounter < this.results.length - 1) {
                    this.arrowCounter = this.arrowCounter + 1;
                    this.fixScrolling();
                }
            },
            handleSelectionUp() {
                if (this.arrowCounter > 0) {
                    this.arrowCounter = this.arrowCounter - 1;
                    this.fixScrolling();
                }
            },
            handleSelection(idx) {
                this.arrowCounter = idx;
            },
            makeBold(str, query) {
                const city_mask = str.replace(/\w/g, '#');

                let query_stripped = query.toLowerCase().replace(/\W/g, '');
                let string_stripped = str.replace(/\W/g, '');

                let index = string_stripped.toLowerCase().indexOf(query_stripped);

                if (index > -1 && query_stripped.length) {
                    let end_index = index + query_stripped.length - 1;

                    const replacer = (i) => {
                        let repl = string_stripped[i];

                        if (i === index) {
                            repl = '<span class="font-semibold text-blue-600 underline">' + repl;
                        }

                        if (i === end_index) {
                            repl = repl + '</span>';
                        }

                        return repl;
                    };
                    let i = -1;

                    return city_mask.replace(/#/g, () => {
                        i++;
                        return replacer(i);
                    });
                }

                return str;
            },
            fixScrolling() {
                if (this.arrowCounter >= this.scrollOffset) {
                    const liH = this.optionRefs[this.arrowCounter].clientHeight;
                    this.$refs.scrollContainer.scrollTop = liH * (this.arrowCounter - this.scrollOffset);
                }
            },
            checkValue(value) {
                return this.options.find((option) => option[this.displayProperty] === value);
            },
            uuid() {
                return uuidv4();
            },
        },
        beforeUpdate() {
            this.optionRefs = [];
        },
        watch: {
            searchInput: function (value) {
                const matchingOption = this.checkValue(value);

                if (matchingOption) {
                    this.$emit('update:modelValue', matchingOption[this.valueProperty]);
                } else {
                    this.$emit('update:modelValue', '');
                }
            },
        },
    };
</script>
