import apiClient from '@/utils/apiClient';

export default {
    getCompanies() {
        return apiClient.get('/companies');
    },
    getCompany(id) {
        return apiClient.get(`/companies/${id}`);
    },
    createCompany(company) {
        return apiClient.post(`/companies`, company);
    },
};
