import apiClient from '@/utils/apiClient';

export default {
    validateInvoices() {
        return apiClient.post('/validate_invoices');
    },
    validateInvoice(invoice_id) {
        return apiClient.post('/validate_invoice', { invoice_id });
    },
    getEnergyProducts() {
        return apiClient.get('/energy_products');
    },
    getEnergySuppliers() {
        return apiClient.get('/energy_suppliers');
    },
    getMeters() {
        return apiClient.get('/meters');
    },
    getInvoicesByClient(client_id, offset, amount) {
        return apiClient.get(`/clients/${client_id}/invoices`, { params: { offset, amount }, showLoader: false });
    },
    getInvoice(invoice_id) {
        return apiClient.get(`/invoice/${invoice_id}`);
    },
    getMeterTariffsByInvoices(invoice_ids) {
        return apiClient.post(`/meter_tariffs_by_invoices`, { invoice_ids });
    },
    getInvoicesByMeterTariff(invoice_ids, meter_tariff_id) {
        return apiClient.get(`/invoices_by_meter_tariff`, { params: { invoice_ids, meter_tariff_id } });
    },
    createInvoice(client_id, invoice) {
        return apiClient.post(`/clients/${client_id}/invoices`, invoice);
    },
    editInvoice(client_id, invoice_id, invoice) {
        return apiClient.put(`/clients/${client_id}/invoices/${invoice_id}`, invoice);
    },
};
