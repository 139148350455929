<template>
    <form @submit.prevent="handleSubmit">
        <div class="space-y-5">
            <MessageBox v-if="errors" />
            <slot />
        </div>
    </form>
</template>

<script>
    export default {
        props: {
            errors: { type: Array, required: false },
        },
        emits: ['handleSubmit'],
    };
</script>
