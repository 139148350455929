<template>
    <header>
        <template v-if="backLinkURL">
            <router-link :to="backLinkURL" class="flex items-center text-gray-400 hover:text-gray-500 mb-6">
                <ArrowLeftIcon class="mr-2 flex-shrink-0 h-4 w-4" aria-hidden="true" />
                <span class="inline-block text-sm font-medium">{{ backLinkText }}</span>
            </router-link>
        </template>

        <div class="flex items-center justify-between">
            <div class="flex-1">
                <h1 v-if="heading === 'h1'" class="text-gray-900 text-3xl font-semibold">{{ title }}</h1>
                <h2 v-else-if="heading === 'h2'" class="text-gray-900 text-2xl font-semibold">{{ title }}</h2>
                <h3 v-else-if="heading === 'h3'" class="text-gray-900 text-xl font-semibold">{{ title }}</h3>
                <h4 v-else-if="heading === 'h4'" class="text-gray-900 text-lg font-semibold">{{ title }}</h4>
                <h5 v-else-if="heading === 'h5'" class="text-gray-900 text-base font-semibold">{{ title }}</h5>
            </div>
            <slot name="actions" />
        </div>
    </header>
</template>

<script>
    import { ArrowLeftIcon } from '@heroicons/vue/solid';

    export default {
        props: {
            title: {
                type: String,
                required: true,
            },
            backLinkURL: {
                type: Object,
                required: false,
            },
            backLinkText: {
                type: String,
                required: false,
                default: 'Back',
            },
            heading: {
                type: String,
                default: 'h1',
            },
        },
        components: {
            ArrowLeftIcon,
        },
    };
</script>
