import apiClient from '@/utils/apiClient';

export default {
    triggerJob() {
        return apiClient.post('/trigger_job');
    },
    getDashboardData() {
        return apiClient.get('/dashboard');
    },
};
