<template>
    <aside class="hidden sm:block border-r border-gray-200 overflow-y-auto" :class="[isSidebarOpen ? 'w-64' : 'w-20']">
        <div class="h-full flex flex-col justify-between">
            <template v-for="subNavigation in navigation" :key="subNavigation">
                <section>
                    <template v-for="group in subNavigation" :key="group">
                        <div v-if="isSidebarOpen" class="mt-5">
                            <span v-if="group.label" class="text-xs tracking-wider uppercase font-medium block mb-2 px-7">{{ $tc(group.label, 1) }}</span>
                            <ul>
                                <li v-for="item in group.children" :key="item" class="group hover:bg-gray-100">
                                    <router-link :to="item.to" exact active-class="bg-gray-100" class="flex items-center cursor-pointer px-7 py-3">
                                        <component :is="item.icon" class="mr-3 flex-shrink-0 h-5 w-5" aria-hidden="true" />
                                        <span class="text-sm font-medium">{{ $tc(item.label, 2) }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <div v-else>
                            <ul>
                                <li v-for="item in group.children" :key="item" class="group text-center hover:bg-gray-100">
                                    <router-link :to="item.to" exact active-class="bg-gray-100" class="block py-3">
                                        <component :is="item.icon" class="flex-shrink-0 h-6 mx-auto" aria-hidden="true" />
                                        <span class="text-xxs">{{ $tc(item.label, 2) }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </template>
                </section>
            </template>
        </div>
    </aside>

    <aside v-if="isSidebarOpen" class="sm:hidden w-64 fixed top-0 left-0 pt-16 h-screen z-10 bg-white border-r border-gray-200 overflow-y-auto">
        <div class="h-full flex flex-col justify-between">
            <template v-for="subNavigation in navigation" :key="subNavigation">
                <section>
                    <template v-for="group in subNavigation" :key="group">
                        <div class="mt-5">
                            <span v-if="group.label" class="text-xs tracking-wider uppercase font-medium block mb-2 px-7">{{ $tc(group.label, 1) }}</span>
                            <ul>
                                <li v-for="item in group.children" :key="item" class="group hover:bg-gray-100">
                                    <router-link :to="item.to" exact active-class="bg-gray-100" class="flex items-center cursor-pointer px-7 py-3" @click="closeSidebar">
                                        <component :is="item.icon" class="mr-3 flex-shrink-0 h-5 w-5" aria-hidden="true" />
                                        <span class="text-sm font-medium">{{ $tc(item.label, 2) }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </template>
                </section>
            </template>
        </div>
    </aside>
</template>

<script>
    import { TemplateIcon, UserGroupIcon, UserCircleIcon, ChartBarIcon, UploadIcon, OfficeBuildingIcon, UsersIcon, AdjustmentsIcon, LogoutIcon } from '@heroicons/vue/outline';

    export default {
        data() {
            return {
                navigation: [
                    [
                        {
                            label: 'my_workspace',
                            children: [
                                {
                                    to: '/',
                                    label: 'dashboard',
                                    icon: TemplateIcon,
                                    organisationTypes: ['root', 'company', 'clients'],
                                },
                                {
                                    to: '/energy_data/electricity_data',
                                    label: 'energy_data',
                                    icon: ChartBarIcon,
                                    organisationTypes: ['root', 'company', 'clients'],
                                },
                                {
                                    to: '/companies',
                                    label: 'company',
                                    icon: OfficeBuildingIcon,
                                    organisationTypes: ['root'],
                                },
                                {
                                    to: '/clients',
                                    label: 'client',
                                    icon: UserGroupIcon,
                                    organisationTypes: ['root', 'company'],
                                },
                                {
                                    to: '/imports',
                                    label: 'import',
                                    icon: UploadIcon,
                                    organisationTypes: ['root', 'company'],
                                },
                            ],
                        },
                        {
                            label: 'user_management',
                            children: [
                                {
                                    to: '/users',
                                    label: 'user',
                                    icon: UsersIcon,
                                    organisationTypes: ['root', 'company', 'client'],
                                },
                                {
                                    to: '/roles',
                                    label: 'role',
                                    icon: UserCircleIcon,
                                    organisationTypes: ['root', 'company', 'client'],
                                },
                                {
                                    to: '/settings',
                                    label: 'setting',
                                    icon: AdjustmentsIcon,
                                    organisationTypes: ['root', 'company', 'client'],
                                },
                            ],
                        },
                    ],
                    [
                        {
                            label: '',
                            children: [{ to: '/logout', label: 'logout', icon: LogoutIcon }],
                        },
                    ],
                ],
            };
        },
        computed: {
            isSidebarOpen() {
                return this.$store.getters['general/isSidebarOpen'];
            },
        },
        methods: {
            closeSidebar() {
                this.$store.dispatch('general/toggleSidebar');
            },
        },
    };
</script>
