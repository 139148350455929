<template>
    <div class="flex inline-flex items-center px-2.5 py-0.5 text-xs font-medium select-none" :class="[colorClasses, square ? '' : 'rounded-full']" v-bind="$attrs">
        <slot />
        <button v-if="$attrs.onOnDelete" class="cursor-pointer ml-2" @click="$emit('onDelete')">
            <XIcon class="flex-shrink-0 h-3.5 w-3.5 text-gray-400 hover:text-gray-500" aria-hidden="true" />
        </button>
    </div>
</template>

<script>
    import { XIcon } from '@heroicons/vue/solid';

    export default {
        props: {
            color: { type: String },
            square: { type: Boolean, default: false },
        },
        inheritAttrs: false,
        components: {
            XIcon,
        },
        computed: {
            colorClasses() {
                switch (this.color) {
                    case 'green':
                        return 'bg-green-200 text-green-800 border-green-400';
                    case 'blue':
                        return 'bg-blue-200 text-blue-800 border-blue-400';
                    case 'red':
                        return 'bg-red-200 text-red-800 border-red-400';
                    case 'yellow':
                        return 'bg-yellow-200 text-yellow-800 border-yellow-400';
                    case 'grey':
                        return 'bg-gray-100 text-gray-800 border-gray-400';
                    case 'orange':
                        return 'bg-orange-100 text-orange-800 border-orange-400';
                    default:
                        return 'bg-gray-100 text-gray-800 border-gray-400';
                }
            },
        },
    };
</script>
