<template>
    <div class="relative pb-10" v-click-outside="closeDropdown">
        <label v-if="label" class="block mb-1 text-sm font-medium text-gray-700" :for="id">
            {{ label }}
            <span v-show="optional" class="inline-flex items-center ml-1 px-2 py-0.5 rounded-md text-xs font-normal bg-gray-100 text-gray-600 select-none">Optional</span>
        </label>

        <div class="w-full">
            <div class="relative flex-1">
                <button
                    type="button"
                    ref="base"
                    @keydown.down="onArrowDown"
                    @keydown.up="onArrowUp"
                    @keydown.enter="onEnter"
                    @keydown.tab="handleClickOutside"
                    @click="toggleDropdown"
                    class="w-full px-3 py-3 rounded border focus:outline-none focus:ring-2 focus:border-transparent text-left"
                    :class="[!getDisplayValue(modelValue) ? 'text-gray-300' : '', error ? 'border-red-500 text-red-900 placeholder-red-300 focus:ring-red-500' : 'border-gray-300 text-grey-900 placeholder-grey-300 focus:ring-indigo-600']"
                >
                    {{ getDisplayValue(modelValue) || placeholder }}

                    <div class="absolute inset-y-0 right-0 flex items-center cursor-pointer" :class="[error ? 'pr-10' : 'pr-3']">
                        <SelectorIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </div>

                    <div v-show="error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>
                </button>
            </div>
        </div>

        <div v-show="isOpen">
            <ul ref="scrollContainer" class="w-full absolute z-10 mt-2 w-full bg-white shadow-lg max-h-60 overflow-auto rounded border">
                <li v-if="options.length <= 0" class="text-gray-500 select-none relative py-2 pl-3 pr-9">
                    <span class="font-normal block truncate italic"> {{ $tc('no_option', 2) }}</span>
                </li>
                <li else v-for="(item, idx) in options" :key="item" :ref="(el) => optionRefs.push(el)" @click="onSelectItem(idx)" @mouseover.prevent="handleSelection(idx)" class="text-gray-900 cursor-pointer select-none relative px-3 py-2 pl-3 pr-9" :class="[idx == arrowCounter ? 'bg-gray-100' : 'bg-white']">
                    <span class="font-normal block truncate">{{ item[displayProperty] }}</span>
                </li>
            </ul>
        </div>

        <p v-show="error" class="mt-2 text-sm text-red-600">{{ error }}</p>
    </div>
</template>

<script>
    import { ExclamationCircleIcon, SelectorIcon } from '@heroicons/vue/solid';
    import i18n from '@/i18n';

    const { t } = i18n.global;

    export default {
        data() {
            return {
                arrowCounter: 0,
                optionRefs: [],
                isOpen: false,
            };
        },
        components: {
            ExclamationCircleIcon,
            SelectorIcon,
        },
        inheritAttrs: false,
        props: {
            id: { type: String, required: true },
            label: { type: String, required: false },
            placeholder: {
                type: String,
                required: false,
                default: () => {
                    return t('select_an_option', 1);
                },
            },
            options: { type: Array, required: true },
            scrollOffset: { type: Number, default: 3 },
            valueProperty: { type: String, required: true },
            displayProperty: { type: String, required: true },
            modelValue: { required: true },
            optional: { type: Boolean, default: false },
            error: { type: String, required: false },
        },
        methods: {
            onArrowDown(e) {
                e.preventDefault();
                this.handleSelectionDown();
            },
            onArrowUp(e) {
                e.preventDefault();
                this.handleSelectionUp();
            },
            onEnter() {
                this.$emit('update:modelValue', this.options[this.arrowCounter][this.valueProperty]);
            },
            toggleDropdown() {
                this.isOpen = !this.isOpen;
            },
            closeDropdown() {
                this.isOpen = false;
            },
            onSelectItem(idx) {
                this.$emit('update:modelValue', this.options[idx][this.valueProperty]);
                this.resetDropdown();
            },
            resetDropdown() {
                this.isOpen = false;
                this.$refs.scrollContainer.scrollTop = 0;
                this.arrowCounter = 0;
                this.$nextTick(() => this.$refs.base.focus());
            },
            handleClickOutside() {
                this.isOpen = false;
                this.arrowCounter = -1;
            },
            handleSelectionDown() {
                if (this.arrowCounter < this.options.length - 1) {
                    this.arrowCounter = this.arrowCounter + 1;
                    this.fixScrolling();
                }
            },
            handleSelectionUp() {
                if (this.arrowCounter > 0) {
                    this.arrowCounter = this.arrowCounter - 1;
                    this.fixScrolling();
                }
            },
            handleSelection(idx) {
                this.arrowCounter = idx;
            },
            getDisplayValue(value) {
                const matchingOption = this.options.find((option) => option[this.valueProperty] === value);
                if (matchingOption) return matchingOption[this.displayProperty];

                return;
            },
            fixScrolling() {
                if (this.arrowCounter >= this.scrollOffset) {
                    const liH = this.optionRefs[this.arrowCounter].clientHeight;
                    this.$refs.scrollContainer.scrollTop = liH * (this.arrowCounter - this.scrollOffset);
                }
            },
        },
        beforeUpdate() {
            this.optionRefs = [];
        },
    };
</script>
