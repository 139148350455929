import apiClient from '@/utils/apiClient';

export default {
    getUsers() {
        return apiClient.get('/users');
    },

    createUser(user) {
        return apiClient.post('/users', user);
    },
};
