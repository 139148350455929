import AppLayout from '@/layouts/AppLayout.vue';

export default [
    {
        path: '/',
        component: () => import('@/pages/dashboards/Dashboard.vue'),
        name: 'dashboard',
        meta: {
            layout: AppLayout,
            requiresAuth: true,
        },
    },
];
