export function SET_CLIENTS(state, clients) {
    state.clients = clients;
}

export function SET_CLIENT(state, client) {
    state.client = client;
}

export function ADD_CLIENT(state, client) {
    state.clients.unshift(client);
}

export function SET_PRODUCTS(state, products) {
    state.products = products;
}

export function SET_EANS(state, eans) {
    state.eans = eans;
}

export function SET_EAN_DATES(state, ean_dates) {
    state.ean_dates = ean_dates;
}

export function ADD_EAN(state, ean) {
    state.eans.unshift(ean);
}

export function SET_INVOICES(state, invoices) {
    state.invoices = invoices;
}

export function SET_CONCLUSIONS(state, conclusions) {
    state.conclusions = conclusions;
}

export function ADD_CONCLUSION(state, conclusion) {
    state.conclusions.unshift(conclusion);
}

export function ADD_INVOICE(state, invoice) {
    state.invoices.unshift(invoice);
}

export function EDIT_INVOICE(state, invoice) {
    state.invoices.unshift(invoice);
}
