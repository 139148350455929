<template>
    <div>
        <label v-if="label" class="block mb-1 text-sm font-medium text-gray-700" :for="id">{{ label }} </label>

        <div class="w-full">
            <div class="relative flex-1">
                <input
                    :type="showPassword ? 'text' : 'password'"
                    :id="id"
                    :value="modelValue"
                    @input="updateValue"
                    :name="uuid()"
                    autocomplete="new-password"
                    autocorrect="off"
                    class="w-full pl-3 py-3 pr-16 rounded border focus:outline-none focus:ring-2 focus:border-transparent"
                    :class="[error ? 'border-red-500 text-red-900 placeholder-red-300 focus:ring-red-500' : 'border-gray-300 text-grey-900 placeholder-grey-300 focus:ring-indigo-600']"
                />

                <div class="absolute inset-y-0 right-0 flex items-center cursor-pointer" :class="[error ? 'pr-10' : 'pr-3']">
                    <EyeIcon v-if="!showPassword" @click="showPassword = true" class="h-5 w-5 text-gray-300" aria-hidden="true" />
                    <EyeOffIcon v-else @click="showPassword = false" class="h-5 w-5 text-gray-300" aria-hidden="true" />
                </div>

                <div v-show="error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
            </div>
        </div>

        <p v-show="error" class="mt-2 text-sm text-red-600">{{ error }}</p>
    </div>
</template>

<script>
    import { v4 as uuidv4 } from 'uuid';
    import { ExclamationCircleIcon, EyeIcon, EyeOffIcon } from '@heroicons/vue/solid';

    export default {
        components: {
            ExclamationCircleIcon,
            EyeIcon,
            EyeOffIcon,
        },
        inheritAttrs: false,
        props: {
            id: { type: String, required: true },
            label: { type: String, required: false },
            modelValue: { required: true },
            error: { type: String, required: false },
        },
        data() {
            return {
                showPassword: false,
            };
        },
        methods: {
            updateValue(event) {
                this.$emit('update:modelValue', event.target.value);
            },
            uuid() {
                return uuidv4();
            },
            getType() {
                return this.showPassword ? 'text' : 'password';
            },
        },
    };
</script>
