import AppLayout from '@/layouts/AppLayout.vue';

export default [
    {
        path: '/settings',
        component: () => import('@/pages/settings/SettingsOverview.vue'),
        redirect: { name: 'energy_markets' },
        name: 'settings',
        meta: {
            layout: AppLayout,
            requiresAuth: true,
        },
        children: [
            {
                path: 'markets',
                component: () => import('@/pages/settings/EnergyMarket.vue'),
                name: 'energy_markets',
                meta: {
                    layout: AppLayout,
                    requiresAuth: true,
                },
            },
            {
                path: 'nace_codes',
                component: () => import('@/pages/settings/NaceCode.vue'),
                name: 'nace_codes',
                meta: {
                    layout: AppLayout,
                    requiresAuth: true,
                },
            },
            {
                path: 'net_operators',
                component: () => import('@/pages/settings/NetOperator.vue'),
                name: 'net_operators',
                meta: {
                    layout: AppLayout,
                    requiresAuth: true,
                },
            },
            {
                path: 'dno_tariffs',
                component: () => import('@/pages/settings/DnoTariff.vue'),
                name: 'dno_tariffs',
                meta: {
                    layout: AppLayout,
                    requiresAuth: true,
                },
            },
            {
                path: 'solar',
                component: () => import('@/pages/settings/Solar.vue'),
                name: 'solar',
                meta: {
                    layout: AppLayout,
                    requiresAuth: true,
                },
            },
            {
                path: 'general',
                component: () => import('@/pages/settings/General.vue'),
                name: 'general',
                meta: {
                    layout: AppLayout,
                    requiresAuth: true,
                },
            },
        ],
    },
];
