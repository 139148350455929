export function isLoggedIn(state) {
    return !!state.token;
}

export function user(state) {
    return state.user;
}

export function organisation_id(state) {
    return state.user.organisation.id;
}

export function organisationType(state) {
    return state.user.organisation.type;
}

export function userPermissions(state) {
    return state.user.role.permissions.map((permission) => permission.name);
}
