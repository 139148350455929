import AuthLayout from '@/layouts/AuthLayout.vue';

export default [
    {
        path: '/login',
        component: () => import('@/pages/auth/Login.vue'),
        name: 'login',
        meta: {
            layout: AuthLayout,
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/logout',
        component: () => import('@/pages/auth/Logout.vue'),
        name: 'logout',
        meta: {
            layout: AuthLayout,
            requiresAuth: true,
        },
    },
    {
        path: '/forgot-password',
        component: () => import('@/pages/auth/ForgotPassword.vue'),
        name: 'forgotPassword',
        meta: {
            layout: AuthLayout,
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/reset-password',
        component: () => import('@/pages/auth/ResetPassword.vue'),
        name: 'resetPassword',
        meta: {
            layout: AuthLayout,
            redirectIfLoggedIn: true,
        },
    },
];
