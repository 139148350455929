import CompanyService from '../../services/CompanyService';

export async function fetchCompanies(context) {
    try {
        if (context.state.companies.length === 0) {
            const response = await CompanyService.getCompanies();
            const companies = response.data.companies;
            return context.commit('SET_COMPANIES', companies);
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function fetchCompany(context, id) {
    try {
        const response = await CompanyService.getCompany(id);
        const company = response.data.company;
        return context.commit('SET_COMPANY', company);
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function createCompany(context, payload) {
    try {
        const response = await CompanyService.createCompany(payload.company);
        const newCompany = response.data.company;
        return context.commit('ADD_COMPANY', newCompany);
    } catch (error) {
        console.log(error);
        return error;
    }
}
