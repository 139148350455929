<template>
    <Menu as="div" class="relative flex justify-end items-center">
        <MenuButton class="w-8 h-8 bg-white border inline-flex items-center justify-center text-gray-400 rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
            <span class="sr-only">Open options</span>
            <DotsHorizontalIcon class="w-5 h-5" aria-hidden="true" />
        </MenuButton>
        <MenuItems class="origin-top-right absolute right-0 top-9 w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
            <MenuItem v-for="action in actions" :key="action">
                <router-link :to="action.to" class="text-gray-700 group flex items-center px-4 py-3 text-sm">
                    {{ action.label }}
                </router-link>
            </MenuItem>
        </MenuItems>
    </Menu>
</template>

<script>
    import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
    import { DotsHorizontalIcon } from '@heroicons/vue/solid';

    export default {
        components: {
            Menu,
            MenuButton,
            MenuItems,
            MenuItem,
            DotsHorizontalIcon,
        },
        props: {
            actions: { type: Array, required: true },
        },
    };
</script>
