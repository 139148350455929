import AuthLayout from '@/layouts/AuthLayout.vue';

export default [
    {
        path: '/404',
        component: () => import('@/pages/general/NotFound.vue'),
        name: '404',
        meta: {
            layout: AuthLayout,
        },
    },
    {
        path: '/:catchAll(.*)',
        redirect: { name: '404' },
    },
];
