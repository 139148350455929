import ClientService from '../../services/ClientService';
import InvoiceService from '../../services/InvoiceService';
import ConclusionService from '../../services/ConclusionService';

export async function fetchClients(context) {
    try {
        if (context.state.clients.length === 0) {
            const response = await ClientService.getClients();
            const clients = response.data.clients;
            return context.commit('SET_CLIENTS', clients);
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function fetchClient(context, id) {
    try {
        const response = await ClientService.getClient(id);
        const client = response.data.client;
        context.commit('SET_CLIENT', client);
        return client;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function createClient(context, payload) {
    try {
        const response = await ClientService.createClient(payload.company_id, payload.client);
        const newClient = response.data.client;
        context.commit('ADD_CLIENT', newClient);
        return newClient;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function fetchProducts(context, client_id) {
    try {
        const response = await ClientService.getProducts(client_id);
        const products = response.data.products;
        return context.commit('SET_PRODUCTS', products);
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function activateProduct(context, payload) {
    try {
        await ClientService.activateProduct(payload.client_id, payload.product_id, payload.product);
        return await context.dispatch('fetchProducts', payload.client_id);
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function fetchEans(context, id) {
    try {
        const response = await ClientService.getEans(id);
        const eans = response.data.eans;
        return context.commit('SET_EANS', eans);
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function createEan(context, payload) {
    try {
        const response = await ClientService.createEan(payload.client_id, payload.ean);
        const newEan = response.data.ean;
        return context.commit('ADD_EAN', newEan);
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function fetchEanDates(context, client_id) {
    try {
        const response = await ClientService.getClientEanDates(client_id);
        const ean_dates = response.data.ean_dates;
        return context.commit('SET_EAN_DATES', ean_dates);
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function fetchInvoices(context, client_id) {
    try {
        const response = await InvoiceService.getInvoicesByClient(client_id);
        const invoices = response.data.invoices;
        return context.commit('SET_INVOICES', invoices);
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function fetchConclusions(context, client_id) {
    try {
        const response = await ConclusionService.getConclusionsByClient(client_id);
        const conclusions = response.data.conclusions;
        return context.commit('SET_CONCLUSIONS', conclusions);
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function createConclusion(context, payload) {
    try {
        const response = await ConclusionService.createConclusion(payload.client_id, payload.conclusion);
        const newConclusion = response.data.conclusion;
        return context.commit('ADD_CONCLUSION', newConclusion);
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function createInvoice(context, payload) {
    try {
        const response = await InvoiceService.createInvoice(payload.client_id, payload.invoice);
        const newInvoice = response.data.invoice;
        return context.commit('ADD_INVOICE', newInvoice);
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function editInvoice(context, payload) {
    try {
        const response = await InvoiceService.editInvoice(payload.client_id, payload.invoice_id, payload.invoice);
        const editInvoice = response.data.invoice;
        return context.commit('EDIT_INVOICE', editInvoice);
    } catch (error) {
        console.log(error);
        return error;
    }
}
