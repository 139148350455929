<template>
    <div class="h-screen bg-gray-100">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'AuthLayout',
    };
</script>
