import DashboardDataService from '../../services/DashboardDataService';

export async function fetchDashboardData(context) {
    try {
        const response = await DashboardDataService.getDashboardData();
        const dashboard_data = response.data;
        return context.commit('SET_DASHBOARD_DATA', dashboard_data);
    } catch (error) {
        console.log(error);
        return error;
    }
}
