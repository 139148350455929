<template>
    <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden" @mouseenter="clearTimer" @mouseleave="startTimer">
            <div class="p-4">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <CheckCircleIcon v-if="type === 'success'" class="h-6 w-6 text-green-400" aria-hidden="true" />
                        <ExclamationCircleIcon v-else class="h-6 w-6 text-red-400" aria-hidden="true" />
                    </div>
                    <div class="mx-4 flex-1">
                        <p class="text-sm font-medium text-gray-900">{{ title }}</p>
                        <p v-show="description" class="mt-1 text-sm text-gray-500">{{ description }}</p>
                    </div>
                    <div class="flex-shrink-0">
                        <button @click="remove" class="bg-white cursor-pointer rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <span class="sr-only">Close</span>
                            <XIcon class="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/outline';
    import { XIcon } from '@heroicons/vue/solid';

    export default {
        props: ['id', 'type', 'title', 'description'],
        data() {
            return {
                timer: null,
            };
        },
        components: {
            CheckCircleIcon,
            ExclamationCircleIcon,
            XIcon,
        },
        created() {
            this.startTimer();
        },
        // watch: {
        //     $route() {
        //         this.remove();
        //     },
        // },
        methods: {
            remove() {
                this.$store.dispatch('notifications/removeNotification', {
                    id: this.id,
                });
            },
            startTimer() {
                this.timer = setTimeout(this.remove, 5000);
            },
            clearTimer() {
                clearTimeout(this.timer);
            },
        },
    };
</script>
