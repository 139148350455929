import AppLayout from '@/layouts/AppLayout.vue';

export default [
    {
        path: '/imports',
        component: () => import('@/pages/imports/ImportList.vue'),
        name: 'imports',
        meta: {
            layout: AppLayout,
            requiresAuth: true,
        },
    },
];
