export function SET_ROLES(state, roles) {
    state.roles = roles;
}

export function SET_ROLE(state, role) {
    state.role = role;
}

export function SET_PERMISSIONS(state, permissions) {
    state.permissions = permissions;
}

export function ADD_ROLE(state, role) {
    state.roles.unshift(role);
}
