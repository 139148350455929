<template>
    <NotificationList />
    <component :is="layout">
        <router-view />
    </component>
</template>

<script>
    import NotificationList from '@/components/notifications/NotificationList.vue';

    export default {
        components: {
            NotificationList,
        },
        computed: {
            layout() {
                return this.$route.meta.layout;
            },
        },
    };
</script>
