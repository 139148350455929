import { v4 as uuidv4 } from 'uuid';

export function addNotification(context, payload) {
    const notification = {
        id: uuidv4(),
        type: payload.type,
        title: payload.title,
        description: payload.description,
    };

    return context.commit('ADD_NOTIFICATION', notification);
}

export function removeNotification(context, id) {
    context.commit('REMOVE_NOTIFICATION', id);
}
