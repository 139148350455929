<template>
    <div :class="alignRight ? 'text-right' : 'text-left'">
        <button v-bind="$attrs" type="submit" class="inline-flex justify-center py-3 px-4 rounded border border-transparent text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-50">
            <svg v-show="isSubmitting" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            {{ isSubmitting ? submittingLabel : label }}
        </button>
    </div>
</template>

<script>
    export default {
        props: {
            label: { type: String, required: true },
            submittingLabel: { type: String, required: false },
            isSubmitting: { type: Boolean, required: false, default: false },
            alignRight: { type: Boolean, required: false, default: false },
        },
        emits: ['click'],
        inheritAttrs: false,
    };
</script>
