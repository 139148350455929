import AppLayout from '@/layouts/AppLayout.vue';

export default [
    {
        path: '/users',
        component: () => import('@/pages/users/UserList.vue'),
        name: 'users',
        meta: {
            layout: AppLayout,
            requiresAuth: true,
        },
    },
];
