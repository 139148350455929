import { createApp } from 'vue';
import vClickOutside from 'click-outside-vue3';
import Maska from 'maska';
import App from './App.vue';
import router from './router';
import store from './store';
import './styles/tailwind.css';

import Form from '@/components/general/Form.vue';
import MessageBox from '@/components/general/MessageBox.vue';
import Spinner from '@/components/general/Spinner.vue';
import PageLoader from '@/components/general/PageLoader.vue';
import Table from '@/components/general/Table.vue';
import TableMenu from '@/components/general/TableMenu.vue';
import Header from '@/components/general/Header.vue';
import Modal from '@/components/general/Modal.vue';
import Button from '@/components/general/Button.vue';
import ButtonGroup from '@/components/general/ButtonGroup.vue';
import Input from '@/components/formElements/Input.vue';
import Password from '@/components/formElements/Password.vue';
import Textarea from '@/components/formElements/Textarea.vue';
import Select from '@/components/formElements/Select.vue';
import MultiSelect from '@/components/formElements/MultiSelect.vue';
import SelectWithSearch from '@/components/formElements/SelectWithSearch.vue';
import Checkbox from '@/components/formElements/Checkbox.vue';
import InputWithButton from '@/components/formElements/InputWithButton.vue';
import RadioButtonGroup from '@/components/formElements/RadioButtonGroup.vue';
import SubmitButton from '@/components/formElements/SubmitButton.vue';

import i18n from '@/i18n';
import moment from 'moment';

require('@/store/subscriber');

store.dispatch('auth/attempt', localStorage.getItem(process.env.VUE_APP_API_TOKEN_NAME)).then(() => {
    const app = createApp(App);

    // Components
    app.component('Form', Form);
    app.component('MessageBox', MessageBox);
    app.component('Spinner', Spinner);
    app.component('PageLoader', PageLoader);
    app.component('Table', Table);
    app.component('TableMenu', TableMenu);
    app.component('Header', Header);
    app.component('Modal', Modal);
    app.component('Button', Button);
    app.component('ButtonGroup', ButtonGroup);
    app.component('Input', Input);
    app.component('Password', Password);
    app.component('Textarea', Textarea);
    app.component('Checkbox', Checkbox);
    app.component('Select', Select);
    app.component('MultiSelect', MultiSelect);
    app.component('SelectWithSearch', SelectWithSearch);
    app.component('InputWithButton', InputWithButton);
    app.component('RadioButtonGroup', RadioButtonGroup);
    app.component('SubmitButton', SubmitButton);

    app.use(Maska);
    app.use(i18n);
    app.use(store);
    app.use(router);
    app.use(vClickOutside);

    app.config.globalProperties.$filters = {
        getHumanDate(date) {
            if (date != null) {
                return moment(date).format('DD/MM/YYYY');
            }
            return '-';
        },
        intToTime(time) {
            const base_time = moment().startOf('day');
            return moment(base_time).add(time, 'hours').format('HH:mm');
        },
        getHumanPrice(value, decimals) {
            return parseFloat(value).toFixed(decimals) + ' €';
        },
        getDecimalValue(value, decimals) {
            return parseFloat(value).toFixed(decimals);
        },
        getMonthYear(date) {
            return moment(date, 'YYYY-MM-DD').format('YYYY-MM');
        },
        makeDecimal(value) {
            if (typeof value !== 'undefined') {
                return value.replace(',', '.');
            } else {
                value;
            }
        },
        capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
    };

    app.mount('#app');
});
