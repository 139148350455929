export function clients(state) {
    return state.clients;
}

export function client(state) {
    return state.client;
}

export function profileDetails(state) {
    if (Object.keys(state.client).length > 0 && Object.keys(state.client.profile).length > 0) {
        const profile = state.client.profile || {};

        const name = state.client.profile.type === 'b2b' ? state.client.profile.business_name : `${state.client.profile.first_name} ${state.client.profile.last_name}`;
        const enterprise_number = state.client.profile.enterprise_number;

        const addresses = state.client.addresses || [];

        const physicalAddresses = addresses.filter((address) => address.pivot.address_type === 'physical');
        const physicalAddress = physicalAddresses[0] || {};
        const physicalAddressPostalCode = physicalAddress.postal_code || {};

        const street = physicalAddress.street || '';
        const number = physicalAddress.number || '';
        const addition = physicalAddress.addition || '';
        const zip = physicalAddressPostalCode.zip || '';
        const city = physicalAddressPostalCode.sub_city || '';

        const email = profile.email || '';
        const phone = profile.phone || '';

        return {
            name,
            enterprise_number,
            street,
            number,
            addition,
            zip,
            city,
            email,
            phone,
        };
    }

    return {};
}

export function name(state) {
    if (Object.keys(state.client).length > 0 && Object.keys(state.client.profile).length > 0) {
        return state.client.profile.type === 'b2b' ? state.client.profile.business_name : `${state.client.profile.first_name} ${state.client.profile.last_name}`;
    }

    return '';
}
export function clientType(state) {
    if (Object.keys(state.client).length > 0 && Object.keys(state.client.profile).length > 0) {
        return state.client.profile.type === 'b2b' ? 'b2b' : 'b2c';
    }
    return '';
}

export function enterpriseNumber(state) {
    if (Object.keys(state.client).length > 0 && Object.keys(state.client.profile).length > 0) {
        return state.client.profile.enterprise_number;
    }

    return '';
}

export function contacts(state) {
    if (Object.keys(state.client).length > 0 && state.client.contacts.length > 0) {
        return state.client.contacts;
    }

    return [];
}

export function products(state) {
    return state.products;
}

export function eans(state) {
    return state.eans;
}
export function ean_dates(state) {
    return state.ean_dates;
}

export function invoices(state) {
    return state.invoices;
}

export function conclusions(state) {
    return state.conclusions;
}
