export default function () {
    return {
        clients: [],
        client: {},
        products: [],
        eans: [],
        ean_dates: [],
        invoices: [],
        conclusions: [],
    };
}
