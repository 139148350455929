/* SIDEBAR */

export function toggleSidebar(context) {
    context.commit('TOGGLE_SIDEBAR');
}

export function openSidebar(context) {
    context.commit('CLOSE_SIDEBAR');
}

export function closeSidebar(context) {
    context.commit('OPEN_SIDEBAR');
}
