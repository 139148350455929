<template>
    <div class="grid grid-cols-3 mb-3" v-if="withSearch"><Input type="text" id="client_search" v-model="search" :placeholder="`${$tc('search', 1)} ...`" @keydown="handleTimer" /></div>
    <div class="grid grid-cols-2 items-center" v-if="withPagination">
        <div>
            {{ $tc('showing', 1) + ' ' + (offset * amount + 1) + ' - ' + (amount * (offset + 1) > total_count ? total_count : amount * (offset + 1)) + ' ' + $tc('of', 1).toLowerCase() + ' ' + total_count + ' ' + $tc('result', total_count).toLowerCase() }}
        </div>
        <div class="flex justify-end items-center space-x-4" v-show="total_count > paginationOptions[0]">
            <span>{{ $tc('result_per_page', 2) }} :</span>
            <select v-model="amount" class="rounded border-gray-300">
                <option v-for="option in paginationOptions" :key="option">
                    {{ option }}
                </option>
            </select>
        </div>
    </div>
    <slot></slot>
    <div class="flex justify-end items-center space-x-2 select-none font-sm" v-show="total_count > paginationOptions[0] && Math.ceil(total_count / amount) > 1" v-if="withPagination">
        <button class="flex items-center cursor-pointer font-semibold uppercase disabled:opacity-20" @click="handleClickDecrease" :disabled="visual_offset === 1">
            <ChevronLeftIcon class="h-5 w-5" />
            <span class="mt-0.5">{{ $tc('previous', 1) }}</span>
        </button>
        <span class="text-gray-300">|</span>
        <input class="w-12 rounded border-gray-300 focus:outline-none focus:ring-2 focus:border-transparent" type="number" v-model="visual_offset" />
        <span class="text-gray-500">{{ $tc('out_of', 1).toLowerCase() }}</span>
        <span class="text-gray-500">{{ Math.ceil(total_count / amount) }}</span>
        <span class="text-gray-300">|</span>
        <button class="flex items-center cursor-pointer font-semibold uppercase disabled:opacity-20" @click="handleClickIncrease" :disabled="visual_offset === Math.ceil(total_count / amount)">
            <span class="mt-0.5">{{ $tc('next', 1) }}</span>
            <ChevronRightIcon class="h-5 w-5" />
        </button>
    </div>
</template>

<script>
    import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/outline';

    export default {
        data() {
            return {
                amount: this.paginationOptions[0],
                offset: 0,
                visual_offset: 1,
                search: '',
                timer: 0,
            };
        },
        props: {
            paginationOptions: { type: Array, required: false, default: () => [10, 20, 50] },
            total_count: { type: Number, required: true, default: 0 },
            withPagination: { type: Boolean, required: false, default: () => true },
            withSearch: { type: Boolean, required: false, default: () => true },
        },
        emits: ['fetchData'],
        components: {
            ChevronLeftIcon,
            ChevronRightIcon,
        },
        watch: {
            visual_offset: {
                handler() {
                    this.offset = this.visual_offset - 1;
                    this.$emit('fetchData', this.search, this.offset, this.amount);
                },
            },
            amount: {
                handler() {
                    this.offset = 0;
                    this.visual_offset = 1;
                    this.$emit('fetchData', this.search, this.offset, this.amount);
                },
            },
        },
        methods: {
            handleClickIncrease() {
                if (this.visual_offset + 1 <= Math.ceil(this.total_count / this.amount)) {
                    this.visual_offset += 1;
                }
            },
            handleClickDecrease() {
                if (this.visual_offset - 1 > 0) {
                    this.visual_offset -= 1;
                }
            },
            handleTimer() {
                console.log('timer');
                clearTimeout(this.timer);
                this.timer = setTimeout(this.refetch, 1000);
            },
            refetch() {
                this.offset = 0;
                this.visual_offset = 1;
                this.$emit('fetchData', this.search, this.offset, this.amount);
            },
        },
    };
</script>
<style scoped>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
</style>
