export default {
    other_legal_forms: 'Andere RechtsFormen',
    professional_association: 'BerufsVEreinigung',
    civil_law_partnership_in_the_form_of_a_private_company_with_limited_liability: 'Gesellschaft des Bürgerlichen Recht in Form einer PrivatGesellsch aft Mit Beschränkter Haftung',
    civil_law_partnership_in_the_form_of_a_limited_partnership_with_actions: 'Gesellschaft des Bürgerlichen Recht in Form einer KommanditGes ellschaft Auf Aktien',
    civil_law_partnership_in_the_form_of_a_limited_partnership_with_limited_liability: 'Gesellschaft des Bürgerlichen Recht in Form einer GENossenschaf t Mit Beschrankter Haftplicht',
    civil_law_partnership_in_the_form_of_a_limited_partnership_with_unlimited_liability: 'Zivilrechtliche Gesellschaft in der Rechtsform einer Gen. Mit Unbeschränkte r Haftung',
    civil_law_partnership_in_the_form_of_limited_partnership_with_unlimited_joint_and_several_liability: 'Gesellschaft des Bürgerlichen Rechts in Form einer GEN. Mit Unbeschränkte r Gesamtschulde nerischer Haftplicht',
    civil_law_partnership_in_the_form_of_a_partnership_with_unlimited_joint_and_several_liability: 'Gesellschaft des Bürgerlichen Rechts in Form einer KommanditGes ellschaft',
    civil_company_in_the_form_of_an_agricultural_company: 'Gesellschaft des Bürgerlichen Rechts in Form einer Landwirtschaftl iche Gesellschaft',
    civil_company_in_the_form_of_a_public_limited_company: 'Gesellschaft des Bürgerlichen Rechts in Form einer AktienGesellsch aft',
    civil_company_in_the_form_of_a_partnership: 'Gesellschaft des Bürgerlichen Rechts in Form einer Offene HandelsGesells chaft',
    private_limited_liability_company: 'PrivatGesellsch aft Mit Beschränkter Haftung',
    private_limited_liability_company_with_social_purpose: 'PrivatGesellsch aft Mit Beschränkter Haftung mit Sozialer Zweckbestimm ung',
    commercial_company: 'Kommerzielles Unternehmen',
    cooperative_company: 'GENossenschaf t',
    limited_joint_stock_company: 'KommanditGes ellschaft Auf Aktien',
    limited_liability_partnership_with_a_social_purpose: 'KommanditGes ellschaft Auf Aktien mit Sozialer Zweckbest immung',
    limited_liability_partnership: 'GENossenschaf t Mit Beschränkter Haftplicht',
    cooperative_society_by_participation_cvba: 'GENMBH, GENossenschaf t Auf Beteiligung',
    cooperative_company_with_limited_liability_for_social_purpose: 'GENossenschaf t Mit Beschränkter Haftplicht mit Sozialer Zweckbest immung',
    cooperative_company_with_unlimited_liability: 'Genossenschaft Mit Unbeschränkte r Haftung',
    cooperative_company_with_unlimited_liability_for_social_purposes: 'Genossenschaft mit unbeschränkter Haftung mit sozialer Zielsetzung',
    limited_partnership_with_unlimited_joint_and_several_liability: 'GEN. Mit Unbeschränkte r Gesamtschulde nerischer Haftplicht',
    cooperative_society_by_participation_cvoha: 'GenmugH, Genossenschaft auf Beteiligung',
    cooperative_company_with_unlimited_joint_and_several_liability_for_social_purposes: 'GENossenschaf t Mit Unbeschränkte r Gesamtschulde nerischer Haftplicht mit SoZialer Zweckbestimm ung',
    one_man_limited_liability_company: 'Einmann PGMBH',
    one_man_limited_liability_company_with_social_purpose: 'Einmann PGMBH mit Sozialer Zweckbestimm ung',
    european_economic_interest_grouping_with_a_registered_office_in_belgium: 'Europäische Wirtschaftliche InteressenVerei nigung',
    economic_interest_grouping_with_a_registered_office_in_belgium: 'Wirtschaftliche InteressenVerei nigung',
    economic_interest_grouping_with_a_social_objective: 'Wirtschaftliche InteressenVerei n mit Sozialer Zweckbestimm ung',
    sole_proprietorship: 'Einzelunternehmen',
    fund_for_social_security: 'Fonds für Existenzsicherung',
    association_de_facto: 'NichtrechtFahli ger Verein',
    ordinary_limited_partnership: 'KommanditGes ellschaft',
    limited_partnership_with_a_social_purpose: 'KommanditGes ellschaft mit Sozialer Zweckbestimm ung',
    international_association: 'Internationale Vereinigung',
    public_benefit_organisation: 'Gemeinnützige Organisation',
    international_non_profit_association: 'Internationale Vereinigung Ohne Gewinnerzielun gsabsicht',
    non_profit_organisation: 'Einrichtung Ohne Gewinnerzielun gsabsicht',
    cost_sharing_association: 'Kostenteilende VEreinigung',
    farming_association: 'Landwirtschaftl iche Gesellschaft',
    partnership: 'Gesellschaft des Allgemeinen Rechts',
    mutuality: 'KrANKENKAsse',
    public_limited_company: 'AktienGesellsch aft',
    public_limited_company_with_social_purpose: 'AktienGesellsch aft mit Sozialer Zweckbestimm ung',
    pension_funding_organisation: 'Organismus für die Finanzierung von Pensionen',
    mutual_insurance_companies: 'PRivatrechtlich e VersicherungsG esellschaft Auf Gegenseitigkeit',
    private_foundation: 'PRivate STiftung',
    other_forms_of_private_law_with_legal_personality: 'Andere PRivatrechtlich e RechtsForm Mit RechtsPersönlic hkeit',
    european_cooperative_society: 'Europäische Genossenschaf t',
    european_company: 'Europäische Gesellschaft',
    silent_trade_company: 'Stille Gesellschaft',
    public_utility_foundation: 'GEMeinnutzige STiftung',
    foundation: 'STIFtung',
    temporary_joint_venture: 'GelegenheitsG esellschaft',
    temporary_association: 'Vorläufige Vereinigung',
    union: 'GEWERKSChaft',
    association_of_co_owners: 'MitEigentümer Verband',
    company_under_firma: 'Offene HandelsGesells chaft',
    company_with_a_social_purpose: 'Offene HandelsGesells chaft mit SoZialer Zweckbestimm ung',
    company_in_formation: 'In Gründung Befindliche Gesellschaft',
    company_or_association_without_legal_personality: 'Gesellschaften oder Vereinigungen ohne Rechtspersönlic hkeit',
    non_profit_association: 'Vereinigung Ohne Erwerbszweck',
    av: 'ARF',
    bv: 'BVE',
    bv_bvba: 'GBR PGMBH',
    bv_cva: 'GBR KGAA',
    bv_cvba: 'GBR GENMBH',
    bv_cvoa: 'GBR GMUH',
    bv_cvoha: 'GBR GENMUGH',
    bv_gcv: 'GBR KG',
    bv_lv: 'GBR LG',
    bv_nv: 'GBR AG',
    bv_vof: 'GBR OHG',
    bvba: 'PGMBH',
    bvba_so: 'PGMBH SZ',
    com_m: 'KU',
    cv: 'GEN',
    cva: 'KGAA',
    cva_so: 'KGAA SZ',
    cvba: 'GENMBH',
    cvba_cd: 'GENMBH GENAB',
    cvba_so: 'GENMBH SZ',
    cvoa: 'GMUH',
    cvoa_so: 'GMUH SZ',
    cvoha: 'GENMUGH',
    cvoha_cd: 'GENMUGH GENAB',
    cvoha_so: 'GENMUGH SZ',
    ebvba: 'EPGMBH',
    ebvba_so: 'EPGMBH SZ',
    eesv: 'EWIV',
    esv: 'WIV',
    esv_so: 'VIV SZ',
    ez: 'EU',
    fbz: 'FEZ',
    fv: 'NFV',
    gcv: 'KG',
    gcv_so: 'KG SZ',
    int_ver: 'INT VER',
    ion: 'GO',
    ivzw: 'IVOG',
    izw: 'EOG',
    kv: 'KVE',
    lv: 'LG',
    ms: 'GAR',
    mutualit: 'KANKENKA',
    nv: 'AG',
    nv_so: 'AG SZ',
    ofp: 'OFP',
    ovzm: 'PRVGAG',
    priv_st: 'PRST',
    prv: 'PRRFMRP',
    sce: 'SCE',
    se: 'SE',
    sh: 'SG',
    son: 'GEMST',
    stich: 'STIF',
    th: 'GG',
    tv: 'VV',
    vakbond: 'GEWERKSC',
    vme: 'MEV',
    vof: 'OHG',
    vof_so: 'OHG SZ',
    vv: 'GBG',
    vvzrl: 'GVORP',
    vzw: 'VOE',
};
