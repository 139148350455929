import apiClient from '@/utils/apiClient';

export default {
    getRoles() {
        return apiClient.get('/roles');
    },

    getRoleById(id) {
        return apiClient.get(`/roles/${id}`);
    },

    getPermissions() {
        return apiClient.get('/permissions');
    },
    createRole(role) {
        return apiClient.post('/roles', role);
    },
};
