import AppLayout from '@/layouts/AppLayout.vue';

export default [
    {
        path: '/roles',
        component: () => import('@/pages/roles/RoleList.vue'),
        name: 'roles',
        meta: {
            layout: AppLayout,
            requiresAuth: true,
        },
    },
    {
        path: '/roles/create',
        component: () => import('@/pages/roles/CreateRole.vue'),
        name: 'create_role',
        meta: {
            layout: AppLayout,
            requiresAuth: true,
        },
    },
    {
        path: '/roles/:role_id',
        component: () => import('@/pages/roles/RoleDetails.vue'),
        redirect: { name: 'roleSettings' },
        name: 'roleDetails',
        meta: {
            layout: AppLayout,
            requiresAuth: true,
            permissions: [''],
        },
        children: [
            {
                path: 'settings',
                component: () => import('@/pages/roles/RoleSettings.vue'),
                name: 'roleSettings',
            },
            {
                path: 'permissions',
                component: () => import('@/pages/roles/RolePermissions.vue'),
                name: 'rolePermissions',
            },
            {
                path: 'users',
                component: () => import('@/pages/roles/RoleUsers.vue'),
                name: 'roleUsers',
            },
        ],
    },
];
