<template>
    <div>
        <label v-if="label" class="block mb-1 text-sm font-medium text-gray-700" :for="id">{{ label }} <span v-show="optional" class="inline-flex items-center ml-2 px-2 py-1 rounded-md text-xs font-normal bg-gray-100 text-gray-600 select-none">Optional</span></label>

        <div class="w-full">
            <div class="relative flex-1">
                <textarea type="text" :id="id" :value="modelValue" @input="updateValue" v-bind="$attrs" class="w-full px-3 py-2 rounded border focus:outline-none focus:ring-2 focus:border-transparent" :class="[error ? 'border-red-500 text-red-900 placeholder-red-300 focus:ring-red-500' : 'border-gray-300 text-grey-900 placeholder-grey-300 focus:ring-indigo-600']" />

                <div v-show="error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
            </div>
        </div>

        <p v-show="error" class="mt-1 text-sm text-red-600">{{ error }}</p>
    </div>
</template>

<script>
    import { ExclamationCircleIcon } from '@heroicons/vue/solid';

    export default {
        components: {
            ExclamationCircleIcon,
        },
        inheritAttrs: false,
        props: {
            id: { type: String, required: true },
            label: { type: String, required: false },
            modelValue: { type: String, required: true, default: '' },
            optional: { type: Boolean, default: false },
            error: { type: String, required: false },
        },
        methods: {
            updateValue(event) {
                this.$emit('update:modelValue', event.target.value);
            },
        },
    };
</script>

<style scope>
    textarea {
        min-height: 10rem;
        max-height: 10rem;
    }
</style>
