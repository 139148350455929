<template>
    <button v-bind="$attrs" @click="handleClick" class="inline-flex items-center px-4 py-3 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        <slot />
    </button>
</template>

<script>
    export default {
        props: ['to'],
        emits: ['click'],
        methods: {
            handleClick() {
                if (this.to) {
                    this.$router.push(this.to);
                } else {
                    this.$emit('click');
                }
            },
        },
        inheritAttrs: false,
    };
</script>
