import { createStore } from 'vuex';

import general from './general';
import loader from './loader';
import auth from './auth';
import users from './users';
import roles from './roles';
import clients from './clients';
import companies from './companies';
import notifications from './notifications';
import energy_data from './energy_data';
import dashboard from './dashboard';

const store = createStore({
    modules: {
        general,
        auth,
        clients,
        companies,
        dashboard,
        energy_data,
        loader,
        notifications,
        roles,
        users,
    },
});

export default store;
