import apiClient from '@/utils/apiClient';

export default {
    getClients() {
        // return apiClient.get('/clients', { params: { search_value, offset, amount }, showLoader: false });
        return apiClient.get('/clients', { showLoader: false });
    },
    getClient(id) {
        return apiClient.get(`/clients/${id}`);
    },
    getProducts(id) {
        return apiClient.get(`/clients/${id}/products`);
    },
    activateProduct(client_id, product_id, product) {
        return apiClient.post(`/clients/${client_id}/products/${product_id}`, product);
    },
    getEans(client_id, search_value, offset, amount) {
        return apiClient.get(`/clients/${client_id}/eans`, { params: { search_value, offset, amount }, showLoader: false });
    },
    getInvertersByEan(client_id, ean_id) {
        return apiClient.get(`/clients/${client_id}/eans/${ean_id}/inverters`);
    },
    getInvertersByClient(client_id) {
        return apiClient.get(`/clients/${client_id}/inverters`);
    },
    createEan(client_id, ean) {
        return apiClient.post(`/clients/${client_id}/eans`, ean);
    },
    createClient(company_id, client) {
        return apiClient.post(`/companies/${company_id}/clients`, client);
    },
    getSearchContractFormulas(client_id, search_value, offset, amount) {
        return apiClient.get(`/clients/${client_id}/contract_formulas`, { params: { search_value, offset, amount }, showLoader: false });
    },
    createContractFormula(client_id, contract_formula) {
        console.log('contract', contract_formula);
        return apiClient.post(`/clients/${client_id}/contract_formulas`, contract_formula);
    },
};
