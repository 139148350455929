import UserService from '@/services/UserService';

export async function fetchUsers(context) {
    try {
        if (context.state.users.length === 0) {
            const response = await UserService.getUsers();
            const users = response.data.users;
            return context.commit('SET_USERS', users);
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function createUser(context, user) {
    try {
        const response = await UserService.createUser(user);
        const newUser = response.data.user;
        return context.commit('ADD_USER', newUser);
    } catch (error) {
        console.log(error);
        return error;
    }
}
