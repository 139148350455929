<template>
    <div class="relative" v-click-outside="handleClickOutside">
        <label v-if="label" class="block mb-1 text-sm font-medium text-gray-700" :for="id">
            {{ label }}
            <span v-show="optional" class="inline-flex items-center ml-2 px-2 py-1 rounded-md text-xs font-normal bg-gray-100 text-gray-600 select-none">Optional</span>
        </label>
        <div class="relative w-full px-3 py-3 rounded border focus:outline-none focus:ring-2 focus:border-transparent">
            <div class="pr-10">
                <span v-if="selected_options.length <= 0" class="text-gray-500 select-none font-normal block truncate cursor-pointer" @click="toggleDropDown"> {{ placeholder }} </span>
                <div v-else>
                    <ul class="flex flex-wrap gap-2">
                        <li v-for="selected_option in selected_options" :key="selected_option">
                            <Badge square @onDelete="onDeleteOption(selected_option)">{{ getDisplayProperty(selected_option) }}</Badge>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" @click="toggleDropDown">
                <ChevronDownIcon v-if="!isOpen" class="h-5 w-5 text-gray-500" aria-hidden="true" />
                <ChevronUpIcon v-else class="h-5 w-5 text-gray-500" aria-hidden="true" />
            </div>
        </div>
        <div v-show="isOpen">
            <ul class="w-full absolute z-10 mt-2 w-full bg-white shadow-lg max-h-60 overflow-auto rounded border">
                <li v-for="item in options" :key="item">
                    <div @click="onItemClick(item)" @mouseover.prevent="onItemMouseOver(item)" @mouseleave.prevent="onItemMouseLeave()" class="text-gray-900 cursor-pointer select-none relative px-3 py-2 pl-3 pr-9" :class="[itemColorClasses(item)]">{{ item[displayProperty] }}</div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/solid';
    import Badge from '@/components/general/Badge.vue';

    export default {
        components: { ChevronDownIcon, ChevronUpIcon, Badge },
        props: {
            label: { type: String, required: false },
            placeholder: { type: String, required: false, default: 'Select an option' },
            optional: { type: Boolean, default: false },
            modelValue: { required: true },
            options: { type: Array, required: true },
            valueProperty: { type: String, required: true },
            displayProperty: { type: String, required: true },
        },
        data() {
            return {
                isOpen: false,
                mouse_selection: '',
                selected_options: [],
            };
        },
        methods: {
            toggleDropDown() {
                this.isOpen = !this.isOpen;
            },
            onItemClick(item) {
                if (!this.selected_options.includes(item[this.valueProperty])) {
                    this.selected_options = [...this.selected_options, item[this.valueProperty]];
                }
            },
            onDeleteOption(item) {
                this.selected_options = this.selected_options.filter((i) => i !== item);
            },
            onItemMouseOver(item) {
                this.mouse_selection = item[this.valueProperty];
            },
            onItemMouseLeave() {
                this.mouse_selection = '';
            },
            handleClickOutside() {
                this.isOpen = false;
            },
            getDisplayProperty(value) {
                const parent = this.options.find((option) => option[this.valueProperty] === value);

                return parent[this.displayProperty];
            },
            itemColorClasses(item) {
                if (this.mouse_selection == item[this.valueProperty]) {
                    return 'bg-green-200';
                } else if (this.selected_options.includes(item[this.valueProperty])) {
                    return 'bg-green-100';
                }

                return 'bg-white';
            },
        },
        watch: {
            selected_options: function (value) {
                this.$emit('update:modelValue', value);
            },
        },
    };
</script>
