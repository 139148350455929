import AppLayout from '@/layouts/AppLayout.vue';

export default [
    {
        path: '/energy_data',
        component: () => import('@/pages/energy_data/EnergyDashboard.vue'),
        name: 'energyData',
        meta: {
            layout: AppLayout,
            requiresAuth: true,
        },
        children: [
            {
                path: 'electricity_data',
                component: () => import('@/pages/energy_data/electricity_data/ElectricityData.vue'),
                name: 'electricityData',
                redirect: { name: 'elecEndexData' },
                children: [
                    {
                        path: 'endex_data',
                        component: () => import('@/pages/energy_data/electricity_data/ElecEndexData.vue'),
                        name: 'elecEndexData',
                    },
                    {
                        path: 'monthly_endex_data',
                        component: () => import('@/pages/energy_data/electricity_data/ElecMonthlyEndexData.vue'),
                        name: 'elecMonthlyEndexData',
                    },
                    {
                        path: 'epex_spot_data',
                        component: () => import('@/pages/energy_data/electricity_data/EpexSpotData.vue'),
                        name: 'epexSpotData',
                    },
                    {
                        path: 'epex_spot_monthly_data',
                        component: () => import('@/pages/energy_data/electricity_data/EpexSpotMonthlyData.vue'),
                        name: 'epexSpotMonthlyData',
                    },
                ],
            },
            {
                path: 'gas_data',
                component: () => import('@/pages/energy_data/gas_data/GasData.vue'),
                name: 'gasData',
                redirect: { name: 'endexTtfData' },
                children: [
                    {
                        path: 'endex_data',
                        component: () => import('@/pages/energy_data/gas_data/EndexTtfData.vue'),
                        name: 'endexTtfData',
                    },
                    {
                        path: 'ttf_spot_data',
                        component: () => import('@/pages/energy_data/gas_data/SpotTtfData.vue'),
                        name: 'spotTtfData',
                    },
                    {
                        path: 'ztp_spot_data',
                        component: () => import('@/pages/energy_data/gas_data/SpotZtpData.vue'),
                        name: 'spotZtpData',
                    },
                    {
                        path: 'ttf_dam_data',
                        component: () => import('@/pages/energy_data/gas_data/TtfDamData.vue'),
                        name: 'ttfDamData',
                    },
                ],
            },
            {
                path: 'emission_data',
                component: () => import('@/pages/energy_data/emission_data/EmissionData.vue'),
                name: 'emissionData',
            },
        ],
    },
];
