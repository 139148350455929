export function companies(state) {
    return state.companies;
}

export function company(state) {
    return state.company;
}

export function name(state) {
    let name = 'Company details';

    if (Object.keys(state.company).length > 0) {
        name = `${state.company.business_name} - ${state.company.enterprise_number}`;
    }

    return name;
}
