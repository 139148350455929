import apiClient from '@/utils/apiClient';

export default {
    getElectricityEndexData(input_data) {
        return apiClient.get('/get_electricity_endex_data', { params: { ...input_data }, showLoader: false });
    },
    getElectricityMonthlyEndexData() {
        return apiClient.get('/get_electricity_monthly_endex_data');
    },
    getElectricityEpexSpotData(input_data) {
        return apiClient.get('/get_elec_epex_spot_data', { params: { ...input_data }, showLoader: false });
    },
    getElecEpexSpotMonthlyData() {
        return apiClient.get('/get_elec_monthly_epex_spot_data');
    },
    getGasTtfData() {
        return apiClient.get('/get_gas_ttf_data');
    },
    getGasTtfSpotData() {
        return apiClient.get('/get_gas_ttf_spot_data');
    },
    getGasZtpSpotData() {
        return apiClient.get('/get_gas_ztp_spot_data');
    },
    getGasTtfDamData() {
        return apiClient.get('/get_gas_ttf_dam_data');
    },
    getEmissionData() {
        return apiClient.get('/get_emission_data');
    },
    getFixEnergyMarkets() {
        return apiClient.get('/get_fix_energy_markets');
    },
    getVariableEnergyMarkets() {
        return apiClient.get('/get_variable_energy_markets');
    },
};
