export default {
    // ---------- A ----------
    action: 'Action | Actions',
    activate_product: 'Activate product | Activate products',
    add: 'Add',
    addon: 'Addon | Addons',
    address: 'Address | Addresses',
    addition: 'Addition | Additions',
    additional_fee: 'Additional fee | Additional fees',
    adjusting_invoice: 'Adjusting invoice | Adjusting invoices',
    administration_cost: 'Administration cost | Administration costs',
    available_data: 'Available data | Available data',
    advice: 'Advice | Advices',
    advice_simulation_note: 'The simulation below applies the advice to the supplied invoices:',
    advisor: 'Advisor | Advisors',
    advantage: 'Advantage | Advantages',
    amr: "AMR | AMR's",
    amount_of_days: '1 day | {count} days',
    amount_of_weeks: '1 week | {count} weeks',
    amount_of_months: '1 month | {count} months',
    amount_of_years: '1 year | {count} years',
    april: 'April',
    attach_your_file_here: 'Attach your file here | Attach you files here',
    august: 'August',
    average_last_month: 'Average last month | Average last {count} months',
    average_last_year: 'Average last year | Average last {count} years',
    // ---------- B ----------
    b2b: 'B2B',
    b2c: 'B2C',
    back_to: 'Back to',
    back_to_client: 'Back to client | Back to clients',
    back_to_company: 'Back to company | Back to companies',
    back_to_dashboard: 'Back to dashboard',
    back_to_login: 'Back to login',
    back_to_role: 'Back to role | Back to roles',
    belpex: 'EPEX Spot',
    belpex_data: 'EPEX Spot data |EPEX Spot data',
    billing_address: 'Billing address | Billing addresses',
    billing_same_as_physical: 'Billing address same as physical address?',
    browse_file: 'Browse file | Browse files',
    business_name: 'Business name | Business names',
    // ---------- C ----------
    calculate: 'Calculate',
    calculate_slp_consumptions: 'Calculate SLP Consumption | Calculate SLP Consumptions',
    calculation_parameter: 'Calculation parameter | Calculation parameters',
    cancel: 'Cancel | Cancels',
    change_meter_tariff: 'Change the meter tariff | Change the meter tariffs',
    channel: 'Channel | Channels',
    check_total: 'Check total | Check totals',
    checked: 'Checked',
    choice: 'Choice | Choices',
    chp: 'CHP',
    city: 'City | Cities',
    click_to_select: 'Click to select a file | Click to select files',
    client: 'Client | Clients',
    client_detail: 'Client detail | Client details',
    client_type: 'Client type | Client types',
    combination: 'Combination | Combinations',
    company: 'Company | Companies',
    conclusion: 'Conclusion | Conclusions',
    conclusion_advice_charging_installation: 'The simulation of a CHP installation is still under investigation.',
    conclusion_advice_chp_installation: 'The simulation of a CHP installation is still under investigation.',
    conclusion_advice_high_voltage_cabin_neutral: 'The current installation complies with the current regulations.',
    conclusion_advice_meter_optimization_neutral: 'No adjustments to the current meter type are necessary.',
    conclusion_advice_solar_panels: 'The simulation of a solar installation is still under investigation.',
    conclusion_note: '* All proposed advice can only be carried out after network study and approval by the network operator. This also allows us to know if the installations are technically possible.',
    confirm_password: 'Confirm password | Confirm passwords',
    consumption_detail: 'Consumption detail | Consumption details',
    consumption_parameter: 'Consumption parameter | Consumption parameters',
    consumptions: 'Consumption | Consumptions',
    contact: 'Contact | Contacts',
    contract_formula: 'Contract formula | Contract formulas',
    contract_type: 'Contract type | Contract types',
    cos_phi: 'Cos phi',
    country: 'Country | Countries',
    creating_client: 'Creating client | Creating clients',
    creating_company: 'Creating company | Creating companies',
    creating_conclusion: 'Creating conclusion | Creating conclusions',
    creating_ean: "Creating EAN | Creating EAN's",
    creating_invoice: 'Creating invoice | Creating invoices',
    creating_role: 'Creating role | Creating roles',
    creating_user: 'Creating user | Creating users',
    create: 'Create',
    create_client: 'Create client | Create clients',
    create_company: 'Create company | Create companies',
    create_conclusion: 'Create conclusion | Create conclusions',
    create_contract_formula: 'Create contract formula | Create contract formulas',
    create_ean: "Create EAN | Create EAN's",
    create_invoice: 'Create invoice | Create invoices',
    create_offer: 'Create offer | Create offers',
    create_role: 'Create role | Create roles',
    create_user: 'Create user | Create users',
    // ---------- D ----------
    dashboard: 'Dashboard | Dashboards',
    data_import: 'Data import | Data imports',
    date: 'Date | Dates',
    date_of_birth: 'Date of birth',
    day: 'Day | Days',
    day_consumption: 'Day consumption | Day consumptions',
    day_tariff: 'Day tariff | Day tariffs',
    december: 'December',
    delete: 'Delete',
    description: 'Description | Descriptions',
    delta_cabin: '∆ Cabin | ∆ Cabins',
    delta_invoice: '∆ Invoice | ∆ Invoices',
    delta_meter: '∆ Meter | ∆ Meters',
    detail: 'Detail | Details',
    degressivity: 'Degressivity | Degressivities',
    difference: 'Difference | Differences',
    direction: 'Direction | Directions',
    disadvantage: 'Disadvantage | Disadvantages',
    disclaimer: 'Disclaimer | Disclaimers',
    discount: 'Discount | Discounts',
    discount_gsc_chp: 'Discount on GSC and CHP | Discounts on GSC and CHP',
    distribution_tariff: 'Distribution tariff | Distibution tariffs',
    dno_tariff: 'DNO tariff | DNO tariffs',
    drag: 'Drag',
    duration_choice: 'Duration choice | Duration choices',
    // ---------- E ----------
    ean: "EAN | EAN's",
    ean_code: 'EAN code | EAN codes',
    ean_detail: 'EAN detail | EAN details',
    edit_invoice: 'Edit invoice | Edit invoices',
    electricity: 'Electricity',
    email: 'Email | Emails',
    enbro: 'Enbro NV',
    enbro_address: 'Kortrijksesteenweg 387',
    enbro_city: 'B - 8530 Harelbeke',
    enbro_disclaimer:
        'The information contained in this communication is for information purposes only, it is not binding on ENBRO and no rights may be derived from it.Although ENBRO takes the utmost care in collecting and processing the data, ENBRO cannot guarantee the accuracy, completeness and / or topicality of the information provided. Therefore, ENBRO explicitly rejects any liability in this respect.Our general terms and conditions apply to all our services.',
    enbro_site: 'www.enbro.com',
    enbro_tel: '+32 (0)9 394 394 0',
    email_address: 'Email address | Email addresses',
    emission: 'Emission | Emissions',
    end_date: 'End date | End dates',
    endex: 'ICE Endex BE',
    endex_monthly: 'ICE Endex BE (monthly)',
    endex_ttf: 'ICE Endex TTF',
    energy_data: 'Energy data | Energy data',
    energy_market: 'Energy market | Energy markets',
    energy_market_name: 'Energy market name | Energy market names',
    energy_product: 'Energy product | Energy products',
    energy_supplier: 'Energy supplier | Energy suppliers',
    enterprise_number: 'Enterprise number | Enterprise numbers',
    evolution: 'Evolution | Evolutions',
    exclusive: 'Exclusive | Exclusives',
    exclusive_consumption: 'Exclusive consumption | Exclusive consumptions',
    exclusive_tariff: 'Exclusive tariff | Exclusive tariffs',
    extend_product: 'Extend product | Extend products',
    extra_information: 'Extra information',
    // ---------- F ----------
    factor: 'Factor | Factors',
    february: 'February',
    female: 'Female | Females',
    federal_contribution_add: 'Federal contribution add | Federal contributions adds',
    federal_contribution_tariff: 'Federal contribution tariff | Federal contribution tariffs',
    fill_in_your_email: 'Please fill in your email address',
    fill_in_your_password: 'Please fill in your password',
    fill_in_the_city: 'Please select a city',
    fill_in_the_country: 'Please select a country',
    fill_in_the_ean_code: 'Please fill in a valid EAN code',
    fill_in_the_number: 'Please fill in a house number',
    fill_in_the_street: 'Please fill in a street name',
    first_name: 'First name | First names',
    fixed_fee: 'Fixed fee | Fixed fees',
    fix: 'Fix | Fixes',
    fix_and_retry: 'Please correct the errors and try again',
    forgot_password: 'Forgot password | Forgot passwords',
    forgot_your_password: 'Forgot your password | Forgot your passwords',
    forgot_your_password_description: 'Enter your email to reset your password | Enter your email to reset your passwords',
    flemish_energy_fund: 'Flemish energy fund | Flemish energy funds',
    flow_rate: 'Flow rate | Flow rates',
    friday: 'Friday | Fridays',
    function: 'Function | Functions',
    // ---------- G ----------
    gas: 'Gas',
    gas_transport_cost: 'Gas transport cost | Gas transport costs',
    gender: 'Gender | Genders',
    general: 'General',
    get_monthly_data: 'Get monthly data',
    get_started_role: 'Get started by creating a new role | Get started by creating new roles',
    get_started_user: 'Get started by creating a new user | Get started by creating new users',
    green_energy_tariff: 'Green energy tariff | Green energy tariffs',
    gsc: 'GSC',
    // ---------- H ----------
    holiday: 'Holiday | Holidays',
    // ---------- I ----------
    id: 'ID | IDs',
    import: 'Import | Imports',
    included_in_price: 'Included in the price | Included in the prices',
    injection_tariff: 'Injection tariff | Injection tariffs',
    installation_charging_station: 'Installation of a charging station | Installation of charging stations',
    installation_chp_installation: 'Installation of a CHP installation | Installation of a CHP installations',
    installation_high_voltage_cabin: 'Installation of a high-voltage cabin | Installation of high-voltage cabins',
    installation_solar_panel: 'Installation of a solar panel | Installation of solar panels',
    invalid_date_of_birth: 'The provided date of birth is invalid | One or more provided dates of birth are invalid',
    invalid_ean_code: 'The provided EAN code is invalid | One or more provided EAN codes are invalid',
    invalid_email: 'The provided email address is invalid | One or more provided email addresses are invalid',
    invalid_enterprise_number: 'The provided enterprise number is invalid | One or more provided enterprise numbers are invalid',
    invalid_gender: 'The provided gender is invalid | One or more provided genders are invalid',
    invalid_language: 'The provided language is invalid | One or more provided languages are invalid',
    invalid_password: 'The given password is too short | One or more filled in passwords are too short',
    inverter: 'Inverter | Inverters',
    invoice: 'Invoice | Invoices',
    invoice_date: 'Invoice date | Invoice dates',
    invoice_detail: 'Invoice detail | Invoice details',
    invoice_check: 'Invoice check | Invoice checks',
    invoice_check_conclusion: `After reviewing the above invoices, it was found that there is currently a difference of about {total_difference} in your {advantage_type} on the whole of these invoices. This equals to a difference of ± {total_difference_percentage}%.\n{total_reactive_sum_conclusion}`,
    invoice_check_conclusion_option_one: 'Therefore, the small difference can be attribuated to roundings and thus no action needs to be taken at this time.',
    invoice_check_conclusion_option_two: 'The difference is greater than 0,5% and in the disadvantage of the supplier. This situation should be discussed with your advisor.',
    invoice_check_conclusion_option_three: 'The difference is greater than 0,2% and in the favor of the supplier. This situation should be discussed with your advisor.',
    invoice_check_conclusion_total_reactive_sum: `\nNext to that there is one {important} point of attention, the cos φ. This occasionally drops below the value of 0,9. As a result, in certain months a 'penalty' is charged for reactive consumption.{total_reactive_sum_option}\nThe total of the 'fine' for the audited invoices is approximately {total_reactive_sum}.`,
    invoice_check_conclusion_total_reactive_sum_option_one: `\nTo eliminate this 'penalty', an additional study is needed so that the right advice can be given.`,
    invoice_check_conclusion_total_reactive_sum_option_two: `\nIf it should drop even more, then a solution must be provided to eliminate it.`,
    invoice_high_voltage_cabin_conclusion: 'The consumption is too high to remain connected to the low-voltage grid. If the consumption is almost the same or even higher, a switch needs to be made to high-voltage by installing a high-voltage cabin.',
    invoice_meter_optimization_conclusion: 'Switching to low voltage with peak metering (installing another meter) generates savings of about {total_difference} on an annual basis. This is of course very dependent on the consumption.\nThe introduction of the capacity term as from 2022 makes the investment absolutely necessary.',
    invoice_reference: 'Invoice reference | Invoice references',
    invoice_total: 'Invoice total | Invoice totals',
    item: 'Item | Items',
    // ---------- J ----------
    january: 'January',
    july: 'July',
    june: 'June',
    // ---------- K ----------
    kva: 'kVA',
    kw: 'kW',
    kw_max: 'kW max',
    kvarh: 'kVArh',
    // ---------- L ----------
    language: 'Language | Languages',
    legal_entity_type: 'Legal entity type | Legal entity types',
    loading: 'Loading',
    location: 'Location | Locations',
    logout: 'Logout',
    logging_out: 'Logging out...',
    last_name: 'Last name | Last names',
    // ---------- M ----------
    male: 'Male | Males',
    march: 'March',
    may: 'May',
    menu: 'Menu | Menus',
    meter: 'Meter | Meters',
    meter_tariff: 'Meter tariff | Meter tariffs',
    meter_tariff_high_tension_cabine: 'Meter tariff for high tension cabine | Meter tariff for high tension cabines',
    meter_tariff_invoice: 'Meter tariff on invoice | Meter tariffs on invoices',
    meter_tariff_meter_optimization: 'Meter tariff for meter optimization | Meter tariff for meter optimizations',
    meter_type: 'Meter type | Meter types',
    monday: 'Monday | Mondays',
    mono: 'Mono | Monos',
    mono_consumption: 'Mono consumption | Mono consumptions',
    mono_tariff: 'Mono tariff | Mono tariffs',
    month: 'Month | Months',
    monthly_epex_spot: 'EPEX Spot (monthly)',
    my_workspace: 'My workspace | My workspaces',
    mwh: 'MWh',
    // ---------- N ----------
    name: 'Name | Names',
    nace_code: 'Nace code | Nace codes',
    netloss: 'Netloss | Netlosses',
    netloss_day: 'Netloss day | Netlosses day',
    netloss_night: 'Netloss night | Netlosses night',
    net_operator: 'Net operator | Net operators',
    net_operator_zip: 'Net operator active postal code | Net operator active postal codes',
    next: 'Next',
    night: 'Night | Nights',
    night_consumption: 'Night consumption | Night consumptions',
    night_tariff: 'Night tariff | Night tariffs',
    not_included_in_price: 'Not included in the price | Not included in the prices',
    november: 'November',
    no_option: 'No option | No options',
    no_role: 'No role | No roles',
    no_user: 'No user | No users',
    number: 'Number | Numbers',
    // ---------- O ----------
    october: 'October',
    of: 'Of',
    offer: 'Offer | Offers',
    oops: 'Oops!',
    open_option: 'Open option | Open options',
    or: 'Or',
    organisation: 'Organisation | Organisations',
    other: 'Other | Others',
    out_of: 'Out of',
    overview: 'Overview | overviews',
    owner: 'Owner | Owners',
    // ---------- P ----------
    page_not_there: "The page you're looking for is not here.",
    parameter: 'Parameter | Parameters',
    part_one: 'Part one',
    part_three: 'Part three',
    part_two: 'Part two',
    part_without_vat: 'Part without VAT',
    past_week: 'Data from the past week',
    password: 'Password | Passwords',
    password_does_not_match: 'Password does not match | Passwords do not match',
    payment_risk_rate: 'Payment risk rate | Payment risks rates',
    peak_invoice: 'Peak invoice | Peak invoices',
    peak_past_year: 'Peak past year',
    peak_tariff: 'Peak tariff | Peak tariffs',
    period: 'Period | Periods',
    percentage: 'Percentage | Percentages',
    permission: 'Permission | Permissions',
    phone: 'Phone | Phones',
    physical_address: 'Physical address | Physical addresses',
    please_try_agin: 'Please try again',
    previous: 'Previous | Previous',
    price: 'Price | Prices',
    price_index_percentage: 'Price index percentage | Price index percentages',
    product: 'Product | Products',
    prosumer_tariff: 'Prosumer tarrif | Prosumer Tarrifs',
    // ---------- Q ----------
    q: '',
    // ---------- R ----------
    reactive_consumption: 'Reactive consumption | Reactive comsumptions',
    reactive_cost: 'Reactive cost | Reactive costs',
    recalculate: 'Recalculate',
    region_radiation: 'Region of radiation  Regions of radiation',
    reminder_fee: 'Reminder fee | Reminder fees',
    remove_product: 'Remove product | Remove products',
    request_link: 'Request link | Request links',
    requesting_link: 'Requesting link | Requesting links',
    required_field: '{field} is a required field',
    resetting_password: 'Resetting password | Resetting passwords',
    reset_password: 'Reset password | Reset passwords',
    reset_your_password: 'Reset your password | Reset your passwords',
    result: 'Result | Results',
    result_per_page: 'Result per page | Results per page',
    role: 'Role | Roles',
    roof_type: 'Roof type | Roof types',
    // ---------- S ----------
    saturday: 'Saturday | Saturdays',
    save_conclusion: 'Save conclusion | Save conclusions',
    search: 'Search | Searches',
    select_a_file: 'Select a file | Select files',
    select_an_option: 'Select an option | Select options',
    select_ean: 'Select ean | Select eans',
    select_date: 'Select date | Select dates',
    select_invoice: 'Select invoice | Select invoices',
    september: 'September',
    setting: 'Setting | Settings',
    showing: 'Showing',
    sign_in: 'Sign in',
    sign_in_account: 'Sign in to your account',
    sign_in_loader: 'Signing in...',
    slp_profile: 'Slp profile | Slp profiles',
    solar: 'Solar',
    solar_panel: 'Solar panel | Solar panels',
    spot_ttf: 'TTF Spot',
    spot_ztp: 'ZTP Spot',
    start_date: 'Start date | Start dates',
    status: 'Status | Statuses',
    street: 'Street | Streets',
    submitting_invoice: 'Submitting invoice',
    submit_invoice: 'Submit invoice | Submit invoices',
    sunday: 'Sunday | Sundays',
    // ---------- T ----------
    tariff_detail: 'Tariff detail | Tariff details',
    thursday: 'Thursday | Thursdays',
    time: 'Time | Times',
    test: 'Test | Tests',
    total_advance: 'Total advance | Total advances',
    total_consumption: 'Total consumption | Total consumptions',
    total_current_invoice: 'Total current invoice | Total current invoices',
    transmission_tariff: 'Transmission tariff | Transmission tariffs',
    ttf: 'TTF',
    ttf_ztp_spot_market: 'TTF/ZTP Spot market | TTF/ZTP Spot markets',
    ttf_dam: 'TTF Day Ahead',
    tuesday: 'Tuesday | Tuesdays',
    type: 'Type | Types',
    // ---------- U ----------
    unable_to_login: 'We were unable to log you in.',
    unchecked: 'Unchecked',
    undefined: 'Undefined',
    unknown_error: 'Unknown error | Unknown errors',
    unit: 'Unit | Units',
    user: 'User | Users',
    user_management: 'User management | Users management',
    // ---------- V ----------
    valid_from: 'Valid from',
    valid_till: 'Valid till',
    validate_invoice: 'Validate invoice | Validate invoices',
    value: 'Value | Values',
    variable: 'Variable | Variables',
    // ---------- W ----------
    wednesday: 'Wednesday | Wednesdays',
    // ---------- X ----------
    x: '',
    // ---------- Y ----------
    year: 'Year | Years',
    year_1: 'Year + 1',
    year_2: 'Year + 2',
    year_3: 'Year + 3',
    year_consumption: 'Year consumption | Year consumptions',
    your_profile_is: 'Your profile is | Your profiles are',
    // ---------- Z ----------
    zip: 'Postal code | Postal codes',
    ztp: 'ZTP',
};
