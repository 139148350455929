export default {
    other_legal_forms: 'Autre Forme Juridique',
    professional_association: 'Union Professionelle',
    civil_law_partnership_in_the_form_of_a_private_company_with_limited_liability: 'Société CIVile sous forme de Société Privée à Responsabilité Limitée',
    civil_law_partnership_in_the_form_of_a_limited_partnership_with_actions: 'Société CIVile sous forme de Société en Commandite par Actions',
    civil_law_partnership_in_the_form_of_a_limited_partnership_with_limited_liability: 'Société CIVile sous forme de SC à Responsabilité Limitée',
    civil_law_partnership_in_the_form_of_a_limited_partnership_with_unlimited_liability: 'Société CIVile sous forme de Société Coopérative à Responsabilité Illimitée',
    civil_law_partnership_in_the_form_of_limited_partnership_with_unlimited_joint_and_several_liability: 'Société CIVile sous forme de SC à Responsabilité Illimitée et Solidaire',
    civil_law_partnership_in_the_form_of_a_partnership_with_unlimited_joint_and_several_liability: 'Société CIVile sous forme de Société en Commandite Simple',
    civil_company_in_the_form_of_an_agricultural_company: 'Société CIVile sous forme de Société AGRicole',
    civil_company_in_the_form_of_a_public_limited_company: 'Société CIVile sous forme de Société Anonyme',
    civil_company_in_the_form_of_a_partnership: 'Société CIVile sous forme de Société en Nom Collectif',
    private_limited_liability_company: 'Société Privée à Responsabilité Limitée',
    private_limited_liability_company_with_social_purpose: 'Société Privée à Responsabilité Limitée à Finalité Sociale',
    commercial_company: 'ASSociation COMmerciale',
    cooperative_company: 'Société Coopérative',
    limited_joint_stock_company: 'Société en Commandite par Actions',
    limited_liability_partnership_with_a_social_purpose: 'Société en Commandite par Actions à Finalité Sociale',
    limited_liability_partnership: 'Société Coopérative à Responsabilité Limitée',
    cooperative_society_by_participation_cvba: 'SCRL, Coopérative de Participation',
    cooperative_company_with_limited_liability_for_social_purpose: 'Société Coopérative à Responsabilité Limitée à Finalité Sociale',
    cooperative_company_with_unlimited_liability: 'Société Coopérative à Responsabilité Illimitée',
    cooperative_company_with_unlimited_liability_for_social_purposes: 'Société Coopérative à Responsabilité Illimitée et à Finalité Sociale',
    limited_partnership_with_unlimited_joint_and_several_liability: 'Société Coopérative à Responsabilité Illimitée et Solidaire',
    cooperative_society_by_participation_cvoha: 'SCRIS, Coopérative de Participation',
    cooperative_company_with_unlimited_joint_and_several_liability_for_social_purposes: 'Société Coopérative à Responsabilité Illimitée et Solidaire à Finalité Sociale',
    one_man_limited_liability_company: 'SPRL Unipersonnelle',
    one_man_limited_liability_company_with_social_purpose: 'SPRL Unipersonnelle à Finalité Sociale',
    european_economic_interest_grouping_with_a_registered_office_in_belgium: "Groupement Européen d'Intérêt Economique avec un siège société en Belgique",
    economic_interest_grouping_with_a_registered_office_in_belgium: "Groupement d'Intérêt Economique avec un siège en Belgique",
    economic_interest_grouping_with_a_social_objective: "Groupement d'Intérêt Economique à Finalité Sociale",
    sole_proprietorship: 'Professionnel',
    fund_for_social_security: 'Fonds de Sécurité d’Existence',
    association_de_facto: 'Assocation De Fait',
    ordinary_limited_partnership: 'Société en Commandite Simple',
    limited_partnership_with_a_social_purpose: 'Société en Commandite Simple à Finalité Sociale',
    international_association: 'ASSociation INTernationale',
    public_benefit_organisation: "Etablissement d'Utilité Publique",
    international_non_profit_association: 'Association Internationale Sans But Lucratif',
    non_profit_organisation: 'Institution Sans But Lucratif',
    cost_sharing_association: 'Association de FRAIS',
    farming_association: 'Société AGRicole',
    partnership: 'Société de Droit Commun',
    mutuality: 'MUTUalité',
    public_limited_company: 'Société Anonyme',
    public_limited_company_with_social_purpose: 'Société Anonyme à Finalité Sociale',
    pension_funding_organisation: 'Organisme de Financement de Pensions',
    mutual_insurance_companies: "Associations d'assurances MUTUelles",
    private_foundation: 'FONDation PRIVée',
    other_forms_of_private_law_with_legal_personality: 'Autre Forme de Droit Privé avec Personnalité Juridique',
    european_cooperative_society: 'Société Coopérative Européenne',
    european_company: 'Société Européenne (Societas Europaea)',
    silent_trade_company: 'Société Interne',
    public_utility_foundation: "Fondation d'Utilité Publique",
    foundation: 'FONDation',
    temporary_joint_venture: 'Société Momentanée',
    temporary_association: 'ASSociation MOMentanée',
    union: 'SYNDICat',
    association_of_co_owners: 'Association de CoPropriétaires',
    company_under_firma: 'Société en Nom Collectif',
    company_with_a_social_purpose: 'Société en Nom Collectif à Finalité Sociale',
    company_in_formation: 'Société en Formation',
    company_or_association_without_legal_personality: 'Société ou Association Sans Personnalité Juridique',
    non_profit_association: 'Association Sans But Lucratif',
    av: 'AFJ',
    bv: 'UP',
    bv_bvba: 'SCIV SPRL',
    bv_cva: 'SCIV SCA',
    bv_cvba: 'SCIV SCRL',
    bv_cvoa: 'SCIV SCRI',
    bv_cvoha: 'SCIV SCRIS',
    bv_gcv: 'SCIV SCS',
    bv_lv: 'SCIV SAGR',
    bv_nv: 'SCIV SA',
    bv_vof: 'SCIV SNC',
    bvba: 'SPRL',
    bvba_so: 'SPRL FS',
    com_m: 'ASS COM',
    cv: 'SC',
    cva: 'SCA',
    cva_so: 'SCA FS',
    cvba: 'SCRL',
    cvba_cd: 'SCRL CP',
    cvba_so: 'SCRL FS',
    cvoa: 'SCRI',
    cvoa_so: 'SCRI FS',
    cvoha: 'SCRIS',
    cvoha_cd: 'SCRIS CP',
    cvoha_so: 'SCRIS FS',
    ebvba: 'SPRLU',
    ebvba_so: 'SPRLU FS',
    eesv: 'GEIE',
    esv: 'GIE',
    esv_so: 'GIE FS',
    ez: 'PR',
    fbz: 'FSE',
    fv: 'ADF',
    gcv: 'SCS',
    gcv_so: 'SCS FS',
    int_ver: 'ASS INT',
    ion: 'EUP',
    ivzw: 'AISBL',
    izw: 'ISBL',
    kv: 'AFRAIS',
    lv: 'SAGR',
    ms: 'SDC',
    mutualit: 'SOC MUTU',
    nv: 'SA',
    nv_so: 'SA FS',
    ofp: 'OFP',
    ovzm: 'MUTU DP',
    priv_st: 'FONDPRIV',
    prv: 'FDP PJ',
    sce: 'SCE',
    se: 'SE',
    sh: 'SI',
    son: 'FUP',
    stich: 'FOND',
    th: 'SM',
    tv: 'ASS MOM',
    vakbond: 'SYNDIC',
    vme: 'ACP',
    vof: 'SNC',
    vof_so: 'SNC FS',
    vv: 'SF',
    vvzrl: 'SASPJ',
    vzw: 'ASBL',
};
