/* SIDEBAR */

export function TOGGLE_SIDEBAR(state) {
    state.isSidebarOpen = !state.isSidebarOpen;
}

export function OPEN_SIDEBAR(state) {
    state.isSidebarOpen = true;
}

export function CLOSE_SIDEBAR(state) {
    state.isSidebarOpen = false;
}
