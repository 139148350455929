<template>
    <div aria-live="assertive" class="fixed z-50 inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
        <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
            <NotificationItem v-for="notification in notifications" :key="notification.id" v-bind="notification" />
        </div>
    </div>
</template>

<script>
    import NotificationItem from './NotificationItem.vue';

    export default {
        components: {
            NotificationItem,
        },
        computed: {
            notifications() {
                return this.$store.getters['notifications/notifications'];
            },
        },
    };
</script>
