import generalRoutes from './routes/generalRoutes.js';
import authRoutes from './routes/authRoutes.js';
import dashboardRoutes from './routes/dashboardRoutes';
import userRoutes from './routes/userRoutes.js';
import roleRoutes from './routes/roleRoutes.js';
import clientRoutes from './routes/clientRoutes.js';
import companyRoutes from './routes/companyRoutes.js';
import importRoutes from './routes/importRoutes.js';
import energyDataRoutes from './routes/energyDataRoutes.js';
import settingRoutes from './routes/settingRoutes.js';

export default [...generalRoutes, ...authRoutes, ...dashboardRoutes, ...userRoutes, ...roleRoutes, ...clientRoutes, ...companyRoutes, ...importRoutes, ...energyDataRoutes, ...settingRoutes];
