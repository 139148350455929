<template>
    <teleport to="body">
        <div v-show="show" class="fixed z-30 inset-0 bg-gray-700 bg-opacity-75" @click="closeModal" />

        <div v-show="show" :class="[sizeClassModal]">
            <form autocomplete="new-password">
                <div class="bg-white mx-auto rounded-md py-6 px-8 space-y-7 shadow-xl">
                    <header class="flex justify-between items-center">
                        <h2 class="text-xl font-semibold text-gray-700">{{ title }}</h2>
                        <button type="button" class="cursor-pointer" @click="closeModal">
                            <XIcon class="flex-shrink-0 h-6 w-6 text-gray-300 hover:text-gray-400" aria-hidden="true" />
                        </button>
                    </header>

                    <section>
                        <slot></slot>
                    </section>

                    <div class="flex flex-row-reverse">
                        <button type="button" @click="$emit('submit')" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">Create</button>
                        <button type="button" @click="closeModal" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-3 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 sm:mt-0 sm:w-auto sm:text-sm">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </teleport>
</template>

<script>
    import { XIcon } from '@heroicons/vue/solid';

    export default {
        props: {
            size: { type: String, default: 'small' },
            show: {
                type: Boolean,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
        },
        components: {
            XIcon,
        },
        emits: ['close', 'submit'],
        methods: {
            closeModal() {
                this.$emit('close');
            },
        },
        computed: {
            sizeClassModal() {
                return this.size === 'small'
                    ? 'fixed z-40 w-2/4 max-w-lg top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
                    : this.size === 'medium'
                    ? 'fixed z-40 w-3/5 max-w-2xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
                    : this.size === 'large'
                    ? 'fixed z-40 w-4/5 max-w-5xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
                    : this.size === 'full'
                    ? 'fixed z-40 w-full max-w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
                    : 'fixed z-40 w-2/4 max-w-lg top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
            },
        },
    };
</script>
