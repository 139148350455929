import apiClient from '@/utils/apiClient';

export default {
    login(credentials) {
        return apiClient.post('/login', credentials);
    },

    logout() {
        return apiClient.post('logout');
    },

    me() {
        return apiClient.get('/me');
    },

    forgotPassword(email) {
        return apiClient.post('/forgot_password', email);
    },
};
