import apiClient from '@/utils/apiClient';

export default {
    getConclusionsByClient(client_id) {
        return apiClient.get(`/clients/${client_id}/conclusions`);
    },
    createConclusion(client_id, conclusion) {
        return apiClient.post(`/clients/${client_id}/conclusions`, conclusion);
    },
};
