<template>
    <div class="rounded-md border p-4" :class="[type === 'error' ? 'bg-red-50 border-red-500' : 'bg-green-50 border-green-500']">
        <div class="flex">
            <div class="flex-shrink-0">
                <CheckCircleIcon v-if="type === 'success'" class="h-6 w-6 text-green-400" aria-hidden="true" />
                <ExclamationCircleIcon v-else class="h-6 w-6 text-red-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
                <h2 class="text-sm font-medium text-red-800" v-html="title" />
                <div v-if="messages && messages.length > 0" class="mt-2 text-sm text-red-700">
                    <ul role="list" class="list-disc pl-5 space-y-1">
                        <li v-for="message in messages" :key="message">{{ message }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/outline';
    import { XIcon } from '@heroicons/vue/solid';

    export default {
        props: {
            id: { type: String },
            type: { type: String, default: 'error' },
            title: { type: String, required: true },
            messages: { type: Array, required: false },
        },
        components: {
            CheckCircleIcon,
            ExclamationCircleIcon,
            XIcon,
        },
    };
</script>
