<template>
    <div class="flex space-x-4">
        <label :for="label" class="flex items-center select-none">
            <input type="checkbox" :id="label" :value="value" :checked="checked" v-model="proxyChecked" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
            <span class="inline-block ml-2 select-none">{{ label }}</span>
        </label>
    </div>
</template>

<script>
    export default {
        props: {
            label: { type: String, required: false },
            modelValue: {},
            value: { default: null },
            checked: { type: [Array, Boolean], default: false },
        },
        emits: ['update:modelValue'],
        inheritAttrs: false,
        computed: {
            proxyChecked: {
                get() {
                    return this.modelValue;
                },
                set(newValue) {
                    this.$emit('update:modelValue', newValue);
                },
            },
        },
    };
</script>
