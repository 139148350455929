import AuthService from '@/services/AuthService';

export async function login(context, credentials) {
    const response = await AuthService.login(credentials);
    const token = response.data.token;

    return context.dispatch('attempt', token);
}

export async function attempt(context, token) {
    if (token) {
        context.commit('SET_TOKEN', token);
    }

    if (!context.state.token) {
        return;
    }

    try {
        const response = await AuthService.me();

        const user = response.data.user;
        context.commit('SET_USER', user);

        return user;
    } catch (error) {
        console.log(error);

        context.commit('LOGOUT');
        return error;
    }
}

export async function logout(context) {
    try {
        await AuthService.logout();

        context.commit('SET_TOKEN', null);
        context.commit('SET_USER', null);
        return;
    } catch (error) {
        console.log(error);
        return error;
    }
}
