import RoleService from '../../services/RoleService';

export async function fetchRoles(context) {
    try {
        if (context.state.roles.length === 0) {
            const response = await RoleService.getRoles();
            const roles = response.data.roles;
            return context.commit('SET_ROLES', roles);
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function fetchRole(context, id) {
    const response = await RoleService.getRoleById(id);
    const role = response.data.role;
    return context.commit('SET_ROLE', role);
}

export async function fetchPermissions(context) {
    try {
        if (context.state.permissions.length === 0) {
            const response = await RoleService.getPermissions();
            const permissions = response.data.permissions;
            return context.commit('SET_PERMISSIONS', permissions);
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function createRole(context, role) {
    try {
        const response = await RoleService.createRole(role);
        const newRole = response.data.role;
        return context.commit('ADD_ROLE', newRole);
    } catch (error) {
        console.log(error);
        return error;
    }
}
