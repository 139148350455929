export function roles(state) {
    return state.roles;
}

export function role(state) {
    return state.role;
}

export function permissions(state) {
    return state.permissions;
}
