<template>
    <div class="relative w-screen h-screen">
        <header class="w-full h-16 bg-white fixed z-20 top-0 left-0 border-b border-gray-200 py-2">
            <div class="flex items-center">
                <div class="w-20 text-center">
                    <button type="button" @click="toggleSidebar" class="bg-white p-3 rounded-full text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
                        <span class="sr-only">Open sidebar</span>
                        <MenuIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>

                <div class="-mt-0.5">
                    <router-link :to="{ name: 'dashboard' }">
                        <img src="@/assets/logos/fibonaki.svg" class="h-4" />
                    </router-link>
                </div>
            </div>
        </header>

        <div id="content" class="w-full h-full flex pt-16">
            <Sidebar />
            <main class="flex-1 relative overflow-y-auto mx-auto px-7 py-5 sm:px-10 sm:py-5">
                <slot />
            </main>
        </div>
    </div>

    <!-- <div class="flex flex-col w-screen h-screen">
        <header class="border-b border-gray-200 py-2">
            <div class="flex items-center">
                <div class="w-20 text-center">
                    <button type="button" @click="toggleSidebar" class="bg-white p-3 rounded-full text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
                        <span class="sr-only">Open sidebar</span>
                        <MenuIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>

                <div class="-mt-0.5">
                    <router-link :to="{ name: 'dashboard' }">
                        <img src="@/assets/logos/fibonaki.svg" class="h-4" />
                    </router-link>
                </div>
            </div>
        </header>

        <div class="sm:hidden absolute z-10 bg-white h-screen" v-if="isSidebarOpen">
            <div class="flex flex-col h-full">
                <header class="border-b border-gray-200 py-2">
                    <div class="flex items-center">
                        <div class="w-20 text-center">
                            <button type="button" @click="toggleSidebar" class="bg-white p-3 rounded-full text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
                                <span class="sr-only">Open sidebar</span>
                                <MenuIcon class="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>

                        <div class="-mt-0.5">
                            <router-link :to="{ name: 'dashboard' }">
                                <img src="@/assets/logos/fibonaki.svg" class="h-4" />
                            </router-link>
                        </div>
                    </div>
                </header>
                <aside class="w-64 border-r border-gray-200 flex-1" v-if="isSidebarOpen">
                    <nav class="space-y-6 pt-6">
                        <div v-for="navigationGroup in mainNavigation" :key="navigationGroup">
                            <span v-if="navigationGroup.label" class="text-xs tracking-wider uppercase font-medium block mb-2 px-7">{{ $tc(navigationGroup.label, 1) }}</span>

                            <ul>
                                <li v-for="item in navigationGroup.children" :key="item" class="group hover:bg-gray-100">
                                    <router-link :to="item.to" exact active-class="bg-gray-100" class="flex items-center cursor-pointer px-7 py-3">
                                        <component :is="item.icon" class="mr-3 flex-shrink-0 h-5 w-5" aria-hidden="true" />
                                        <span class="text-sm font-medium">{{ $tc(item.label, 2) }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </aside>
            </div>
        </div>

        <div class="flex flex-1">
            <aside class="hidden sm:block border-r border-gray-200" :class="[isSidebarOpen ? 'w-64' : 'w-20']">
                <nav v-if="isSidebarOpen" class="space-y-6 pt-6">
                    <div v-for="navigationGroup in mainNavigation" :key="navigationGroup">
                        <span v-if="navigationGroup.label" class="text-xs tracking-wider uppercase font-medium block mb-2 px-7">{{ $tc(navigationGroup.label, 1) }}</span>

                        <ul>
                            <li v-for="item in navigationGroup.children" :key="item" class="group hover:bg-gray-100">
                                <router-link :to="item.to" exact active-class="bg-gray-100" class="flex items-center cursor-pointer px-7 py-3">
                                    <component :is="item.icon" class="mr-3 flex-shrink-0 h-5 w-5" aria-hidden="true" />
                                    <span class="text-sm font-medium">{{ $tc(item.label, 2) }}</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </nav>

                <nav v-else class="space-y-6">
                    <ul>
                        <template v-for="navigationGroup in mainNavigation" :key="navigationGroup">
                            <li v-for="item in navigationGroup.children" :key="item" class="group text-center hover:bg-gray-100">
                                <router-link :to="item.to" exact active-class="bg-gray-100" class="block py-3">
                                    <component :is="item.icon" class="flex-shrink-0 h-6 mx-auto" aria-hidden="true" />
                                    <span class="text-xxs">{{ $tc(item.label, 2) }}</span>
                                </router-link>
                            </li>
                        </template>
                    </ul>
                </nav>
            </aside>
            <main class="flex-1 relative">
                <suspense>
                    <template #default>
                        <div class="overflow-y-auto mx-auto p-12">
                            <slot />
                        </div>
                    </template>
                    <template #fallback>
                        <div>loading...</div>
                    </template>
                </suspense>
            </main>
        </div>
    </div> -->
</template>

<script>
    import { defineAsyncComponent } from 'vue';
    import { MenuIcon, TemplateIcon, UserGroupIcon, UserCircleIcon, LogoutIcon, ChartBarIcon, UploadIcon, OfficeBuildingIcon, UsersIcon, AdjustmentsIcon } from '@heroicons/vue/outline';
    import Sidebar from '../components/general/Sidebar.vue';

    const mainNavigation = [
        {
            label: 'my_workspace',
            children: [
                {
                    to: '/',
                    label: 'dashboard',
                    icon: TemplateIcon,
                    organisationTypes: ['root', 'company', 'clients'],
                },
                {
                    to: '/energy_data/electricity_data',
                    label: 'energy_data',
                    icon: ChartBarIcon,
                    organisationTypes: ['root', 'company', 'clients'],
                },
                {
                    to: '/companies',
                    label: 'company',
                    icon: OfficeBuildingIcon,
                    organisationTypes: ['root'],
                },
                {
                    to: '/clients',
                    label: 'client',
                    icon: UserGroupIcon,
                    organisationTypes: ['root', 'company'],
                },
                {
                    to: '/imports',
                    label: 'import',
                    icon: UploadIcon,
                    organisationTypes: ['root', 'company'],
                },
            ],
        },
        {
            label: 'user_management',
            children: [
                {
                    to: '/users',
                    label: 'user',
                    icon: UsersIcon,
                    organisationTypes: ['root', 'company', 'client'],
                },
                {
                    to: '/roles',
                    label: 'role',
                    icon: UserCircleIcon,
                    organisationTypes: ['root', 'company', 'client'],
                },
                {
                    to: '/settings',
                    label: 'setting',
                    icon: AdjustmentsIcon,
                    organisationTypes: ['root', 'company', 'client'],
                },
            ],
        },
    ];

    const accountNavigation = [{ to: '/logout', label: 'logout', icon: LogoutIcon }];

    export default {
        name: 'AppLayout',
        components: {
            MenuIcon,
            Dashboard: defineAsyncComponent(() => import('@/pages/dashboards/Dashboard.vue')),
            Sidebar,
        },
        setup() {
            return {
                mainNavigation,
                accountNavigation,
            };
        },
        data() {
            return {
                isSidebarOpen: false,
            };
        },
        methods: {
            toggleSidebar() {
                this.$store.dispatch('general/toggleSidebar');
            },
        },
    };
</script>

<style scope>
    svg path {
        stroke-width: 1.5;
    }

    .text-xxs {
        font-size: 0.65rem;
        line-height: 0.75rem;
    }
</style>
