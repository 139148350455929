import { createI18n } from 'vue-i18n/index';
import english from '@/i18n/general/en';
import dutch from '@/i18n/general/nl';
import french from '@/i18n/general/fr';
import german from '@/i18n/general/de';
import french_legal_entity from '@/i18n/legal_entity_types/fr';
import dutch_legal_entity from '@/i18n/legal_entity_types/nl';
import english_legal_entity from '@/i18n/legal_entity_types/en';
import german_legal_entity from '@/i18n/legal_entity_types/de';

const messages = {
    en: {
        ...english,
        ...english_legal_entity,
    },
    nl: {
        ...dutch,
        ...dutch_legal_entity,
    },
    fr: {
        ...french,
        ...french_legal_entity,
    },
    de: {
        ...german,
        ...german_legal_entity,
    },
};

const i18n = createI18n({
    // legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages,
});

export default i18n;
