import AppLayout from '@/layouts/AppLayout.vue';

export default [
    {
        path: '/clients',
        component: () => import('@/pages/clients/ClientList.vue'),
        name: 'clients',
        meta: {
            layout: AppLayout,
            requiresAuth: true,
        },
    },
    {
        path: '/clients/create',
        component: () => import('@/pages/clients/CreateClient.vue'),
        name: 'createClient',
        meta: {
            layout: AppLayout,
            requiresAuth: true,
        },
    },
    {
        path: '/clients/:client_id',
        component: () => import('@/pages/clients/ClientDetails.vue'),
        redirect: { name: 'clientOverview' },
        name: 'clientDetails',
        meta: {
            layout: AppLayout,
            requiresAuth: true,
            permissions: [''],
        },
        children: [
            {
                path: 'overview',
                component: () => import('@/pages/clients/ClientOverview.vue'),
                name: 'clientOverview',
            },
            {
                path: 'products',
                component: () => import('@/pages/clients/ClientProducts.vue'),
                name: 'clientProducts',
            },
            {
                path: 'eans',
                component: () => import('@/pages/clients/ClientEans.vue'),
                name: 'clientEans',
            },
            {
                path: 'formulas',
                component: () => import('@/pages/clients/ClientContractFormulas.vue'),
                name: 'clientContractFormulas',
            },
            {
                path: 'inverters',
                component: () => import('@/pages/clients/ClientInverters.vue'),
                name: 'clientInverters',
            },
            {
                path: 'invoices',
                component: () => import('@/pages/invoices/InvoiceDetails.vue'),
                name: 'clientInvoices',
                redirect: { name: 'invoiceOverview' },
                children: [
                    {
                        path: '',
                        component: () => import('@/pages/invoices/InvoicesOverview.vue'),
                        name: 'invoiceOverview',
                    },
                    {
                        path: 'create',
                        component: () => import('@/pages/invoices/CreateInvoice.vue'),
                        name: 'createInvoice',
                    },
                    {
                        path: ':invoice_id/edit',
                        component: () => import('@/pages/invoices/EditInvoice.vue'),
                        name: 'editInvoice',
                    },
                    {
                        path: 'conclusions/create',
                        component: () => import('@/pages/invoices/CreateConclusion.vue'),
                        name: 'createConclusion',
                    },
                ],
            },
            {
                path: 'consumptions',
                component: () => import('@/pages/clients/ClientConsumptions.vue'),
                name: 'clientConsumptions',
            },
            {
                path: 'solar',
                component: () => import('@/pages/solar/SolarDetails.vue'),
                name: 'clientSolar',
                redirect: { name: 'clientSolarOffers' },

                children: [
                    {
                        path: '',
                        component: () => import('@/pages/solar/ClientSolarOffers.vue'),
                        name: 'clientSolarOffers',
                    },
                    {
                        path: 'create',
                        component: () => import('@/pages/solar/CreateSolarOffer.vue'),
                        name: 'createSolarOffer',
                    },
                ],
            },
        ],
    },
];
