import AppLayout from '@/layouts/AppLayout.vue';

export default [
    {
        path: '/companies',
        component: () => import('@/pages/companies/CompanyList.vue'),
        name: 'companies',
        meta: {
            layout: AppLayout,
            requiresAuth: true,
        },
    },
    {
        path: '/companies/create',
        component: () => import('@/pages/companies/CreateCompany.vue'),
        name: 'createCompany',
        meta: {
            layout: AppLayout,
            requiresAuth: true,
        },
    },
    {
        path: '/companies/:company_id',
        component: () => import('@/pages/companies/CompanyDetails.vue'),
        redirect: { name: 'companyOverview' },
        name: 'companyDetails',
        meta: {
            layout: AppLayout,
            requiresAuth: true,
            permissions: [''],
        },
        children: [
            {
                path: 'overview',
                component: () => import('@/pages/companies/CompanyOverview.vue'),
                name: 'companyOverview',
            },
            {
                path: 'users',
                component: () => import('@/pages/companies/CompanyUsers.vue'),
                name: 'companyUsers',
            },
        ],
    },
];
