<template>
    <div>
        <label v-show="label" class="block text-sm font-medium text-gray-700 mb-2">{{ label }}</label>
        <div class="flex space-x-4">
            <div v-for="option in options" :key="option">
                <label :for="option[valueProperty]" class="flex items-center select-none">
                    <input type="radio" :value="option[valueProperty]" :id="option[valueProperty]" :checked="option[valueProperty] === modelValue" @input="updateValue" />
                    <span class="inline-block ml-2">{{ option[displayProperty] }}</span>
                </label>
            </div>
        </div>

        <p v-show="error" class="mt-2 text-sm text-red-600">{{ error }}</p>
    </div>
</template>

<script>
    export default {
        props: {
            label: { type: String, required: false },
            options: { type: Array, required: true },
            valueProperty: { type: String, required: true },
            displayProperty: { type: String, required: true },
            modelValue: { type: String, required: true, default: '' },
            error: { type: String, required: false },
        },
        methods: {
            updateValue(event) {
                this.$emit('update:modelValue', event.target.value);
            },
        },
    };
</script>
