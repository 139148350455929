export default {
    other_legal_forms: 'Other legal forms',
    professional_association: 'Professional association',
    civil_law_partnership_in_the_form_of_a_private_company_with_limited_liability: 'Civil law partnership in the form of a private company with limited liability',
    civil_law_partnership_in_the_form_of_a_limited_partnership_with_actions: 'Civil law partnership in the form of a limited partnership with limited liability',
    civil_law_partnership_in_the_form_of_a_limited_partnership_with_limited_liability: 'Civil law partnership in the form of a limited partnership with limited liability',
    civil_law_partnership_in_the_form_of_a_limited_partnership_with_unlimited_liability: 'Civil law partnership in the form of a limited partnership with unlimited liability',
    civil_law_partnership_in_the_form_of_limited_partnership_with_unlimited_joint_and_several_liability: 'Civil law partnership in the form of limited partnership with unlimited joint and several liability',
    civil_law_partnership_in_the_form_of_a_partnership_with_unlimited_joint_and_several_liability: 'Civil law partnership in the form of a partnership with unlimited joint and several liability',
    civil_company_in_the_form_of_an_agricultural_company: 'Civil company in the form of an agricultural company',
    civil_company_in_the_form_of_a_public_limited_company: 'Civil company in the form of a public limited company',
    civil_company_in_the_form_of_a_partnership: 'Civil company in the form of a partnership',
    private_limited_liability_company: 'Private Limited Liability Company',
    private_limited_liability_company_with_social_purpose: 'Private Limited Liability Company with Social Purpose',
    commercial_company: 'Commercial Company',
    cooperative_company: 'Cooperative Company',
    limited_joint_stock_company: 'Limited Joint Stock Company',
    limited_liability_partnership_with_a_social_purpose: 'Limited Liability Partnership with a Social purpose',
    limited_liability_partnership: 'Limited Liability Partnership',
    cooperative_society_by_participation_cvba: 'Cooperative Society by Participation CVBA',
    cooperative_company_with_limited_liability_for_social_purpose: 'Cooperative Company with Limited Liability for Social Purpose',
    cooperative_company_with_unlimited_liability: 'Cooperative Company with Unlimited Liability',
    cooperative_company_with_unlimited_liability_for_social_purposes: 'Cooperative Company with unlimited liability for social purposes',
    limited_partnership_with_unlimited_joint_and_several_liability: 'Limited partnership with unlimited joint and several liability',
    cooperative_society_by_participation_cvoha: 'Cooperative Society by Participation CVOHA',
    cooperative_company_with_unlimited_joint_and_several_liability_for_social_purposes: 'Cooperative Company with Unlimited Joint and Several Liability for Social Purposes',
    one_man_limited_liability_company: 'One-man limited liability company',
    one_man_limited_liability_company_with_social_purpose: 'One-man limited liability company with social purpose',
    european_economic_interest_grouping_with_a_registered_office_in_belgium: 'European Economic Interest Grouping with a registered office in Belgium',
    economic_interest_grouping_with_a_registered_office_in_belgium: 'Economic Interest Grouping with a registered office in Belgium',
    economic_interest_grouping_with_a_social_objective: 'Economic Interest Grouping with a Social Objective',
    sole_proprietorship: 'Sole proprietorship',
    fund_for_social_security: 'Fund for Social Security',
    association_de_facto: 'Association de facto',
    ordinary_limited_partnership: 'Ordinary Limited Partnership',
    limited_partnership_with_a_social_purpose: 'Limited partnership with a social purpose',
    international_association: 'International Association',
    public_benefit_organisation: 'Public Benefit Organisation',
    international_non_profit_association: 'International non-profit association',
    non_profit_organisation: 'Non-Profit Organisation',
    cost_sharing_association: 'Cost Sharing Association',
    farming_association: 'Farming Association',
    partnership: 'Partnership',
    mutuality: 'Mutuality',
    public_limited_company: 'Public limited company',
    public_limited_company_with_social_purpose: 'Public Limited Company with Social Purpose',
    pension_funding_organisation: 'Pension Funding Organisation',
    mutual_insurance_companies: 'Mutual Insurance companies',
    private_foundation: 'Private Foundation',
    other_forms_of_private_law_with_legal_personality: 'Other Forms of Private Law with Legal Personality',
    european_cooperative_society: 'European Cooperative Society',
    european_company: 'European Company (Societas Europaea)',
    silent_trade_company: 'Silent Trade Company',
    public_utility_foundation: 'Public Utility Foundation',
    foundation: 'Foundation',
    temporary_joint_venture: 'Temporary joint venture',
    temporary_association: 'Temporary Association',
    union: 'Union',
    association_of_co_owners: 'Association of Co-owners',
    company_under_firma: 'Company Under Firma',
    company_with_a_social_purpose: 'Company with a social purpose',
    company_in_formation: 'Company in formation',
    company_or_association_without_legal_personality: 'Company or Association without Legal Personality',
    non_profit_association: 'Non-profit Association',
    av: 'AV',
    bv: 'BV',
    bv_bvba: 'BV BVBA',
    bv_cva: 'BV CVA',
    bv_cvba: 'BV CVBA',
    bv_cvoa: 'BV CVOA',
    bv_cvoha: 'BV CVOHA',
    bv_gcv: 'BV GCV',
    bv_lv: 'BV LV',
    bv_nv: 'BV NV',
    bv_vof: 'BV VOF',
    bvba: 'BVBA',
    bvba_so: 'BVBA SO',
    com_m: 'COM M',
    cv: 'CV',
    cva: 'CVA',
    cva_so: 'CVA SO',
    cvba: 'CVBA',
    cvba_cd: 'CVBA CD',
    cvba_so: 'CVBA SO',
    cvoa: 'CVOA',
    cvoa_so: 'CVOA SO',
    cvoha: 'CVOHA',
    cvoha_cd: 'CVOHA CD',
    cvoha_so: 'CVOHA SO',
    ebvba: 'EBVBA',
    ebvba_so: 'EBVBA SO',
    eesv: 'EESV',
    esv: 'ESV',
    esv_so: 'ESV SO',
    ez: 'EZ',
    fbz: 'FBZ',
    fv: 'FV',
    gcv: 'GCV',
    gcv_so: 'GCV SO',
    int_ver: 'INT VER',
    ion: 'ION',
    ivzw: 'IVZW',
    izw: 'IZW',
    kv: 'KV',
    lv: 'LV',
    ms: 'MS',
    mutualit: 'MUTUALIT',
    nv: 'NV',
    nv_so: 'NV SO',
    ofp: 'OFP',
    ovzm: 'OVZM',
    priv_st: 'PRIV ST',
    prv: 'PRV',
    sce: 'SCE',
    se: 'SE',
    sh: 'SH',
    son: 'SON',
    stich: 'STICH',
    th: 'TH',
    tv: 'TV',
    vakbond: 'VAKBOND',
    vme: 'VME',
    vof: 'VOF',
    vof_so: 'VOF SO',
    vv: 'VV',
    vvzrl: 'VVZRL',
    vzw: 'VZW',
};
